import { useLayout } from 'pages/signup/layout';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { getPrices } from '../../redux/actions/stripeActions';
import { createBilling } from '../../redux/actions/userActions';
import { warningNotification } from '../../redux/notificationSlice';
import LoadingSpinner from '../../components/LoadingSpinner';

const ChoosePlanPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { plans } = useSelector((state: RootState) => state.stripe);
    const { loading, error } = useSelector((state: RootState) => state.user);
    const [selectedPlanId, setSelectedPlanId] = useState<string>();
    const { setPrevPath, setFooterText, setStep } = useLayout();

    useEffect(() => {
        setFooterText(null);
        setStep(null);
        setPrevPath('/signup/welcome');
    });

    const { handleSubmit } = useForm();

    const selectPlan = useCallback((id: string) => {
        setSelectedPlanId(id);
    }, []);

    const onSubmit = useCallback(async () => {
        if (selectedPlanId) {
            const result = await dispatch(createBilling({ priceId: selectedPlanId }));

            if (createBilling.rejected.match(result)) {
                dispatch(warningNotification('Failed to create checkout form.'));
            } else {
                navigate('/signup/checkout');
            }
        } else {
            navigate('/signup/set-profile-picture');
        }
    }, [dispatch, navigate, selectedPlanId]);

    return (
        <form className="flex h-full w-full flex-col items-center justify-start gap-12 px-6 pt-10 text-white">
            <div className="flex flex-col items-center justify-center gap-8">
                <span className="text-2xl">Welcome</span>
                <div className="flex h-full w-full flex-col items-center gap-4">
                    <div className="flex flex-col items-center">
                        <p>For A Limited Time, If You Purchase</p>
                        <p>
                            An Annual Pass, <span className="text-secondary">You Will Be Able To</span>
                        </p>
                        <p>
                            <span className="text-secondary">Gift 1 Extra</span> Annual Pass
                        </p>
                    </div>
                    <div className="text-center text-lg leading-5 text-secondary">
                        <p className="font-semibold">
                            AVERAGE COST OF A PERSONAL TRAINER IS <span className="text-2xl">$1,000</span> PER MONTH
                        </p>
                    </div>
                </div>
            </div>
            {loading ? (
                <LoadingSpinner message="Preparing Your Payment Setup.." className="pt-16" />
            ) : (
                <>
                    <div className="flex w-full flex-col items-center gap-4">
                        {plans.length === 0 && <span className="loading loading-dots loading-lg"></span>}
                        {plans.map((p, i) => (
                            <div
                                onClick={() => selectPlan(p.id)}
                                className={`h-[80px] w-full rounded-2xl bg-black px-5 py-0 ${selectedPlanId === p.id ? 'border-secondary shadow-glow' : 'border-white'} cursor-pointer border`}
                            >
                                <div className="flex h-full flex-row items-center justify-center gap-4">
                                    <div className="flex h-full items-center">
                                        <input
                                            type="checkbox"
                                            checked={selectedPlanId === p.id}
                                            className="checkbox h-4 w-4 rounded-full [--chkbg:theme(colors.secondary)] [--chkfg:clear]"
                                        />
                                    </div>
                                    <div className="flex h-full w-full flex-row justify-between">
                                        <div className="flex h-full flex-col justify-center gap-1">
                                            <p>
                                                <span className="capitalize">1 {p.type}</span>
                                            </p>
                                            <p className="text-xs text-gray-600">{p.label}</p>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <span className={`${selectedPlanId === p.id ? 'text-secondary' : ' '}`}>
                                                $<span className="pl-1 text-2xl">{p.amount}</span>{' '}
                                                <span className="text-xs font-thin">
                                                    {p.label.includes('year') ? '/y' : '/m'}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="itesm-center flex w-full flex-col gap-4">
                        <button
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            className="h-[40px] w-full rounded-full border border-white bg-black"
                        >
                            {'Start Your Free Trial'}
                        </button>
                        <Link to="/" className="text-center text-[10px] font-normal text-gray-600 underline">
                            Skip
                        </Link>
                    </div>
                </>
            )}
        </form>
    );
};

export default ChoosePlanPage;

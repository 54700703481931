import Close from 'icons/close';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slider';
import { AppDispatch, RootState } from 'redux/store';
import { getAllExercises, getAllMuscles, getAllTypes } from '../../redux/actions/exerciseActions';

export type FilterModalProps = {
    modalOpen: boolean;
    minDuration: number;
    maxDuration: number;
    bodyPart: string[];
    setModalOpen: (open) => void;
    setMinDuration: (minDuration: number) => void;
    setMaxDuration: (maxDuration: number) => void;
    setBodyPart: (bodyPart: string[]) => void;
    setIntensity: (intensity: string) => void;
    handleCloseFilter: () => void;
};

export enum IntensityLevel {
    X1 = 'X1',
    X2 = 'X2',
    X3 = 'X3',
    X4 = 'X4',
    X5 = 'X5',
    X6 = 'X6',
    X7 = 'X7',
}

const FilterModal = ({
    modalOpen,
    minDuration,
    maxDuration,
    bodyPart,
    setModalOpen,
    setMinDuration,
    setMaxDuration,
    setBodyPart,
    setIntensity,
    handleCloseFilter,
}: FilterModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { muscles, loading: loadingExercises } = useSelector((state: RootState) => state.exercise);

    useEffect(() => {
        dispatch(getAllMuscles());
        dispatch(getAllTypes());
        dispatch(getAllExercises());
    }, []);

    return (
        <div className={`modal ${modalOpen ? 'modal-open' : ''} left-0 top-0 h-dvh w-screen flex-col`}>
            <div className="modal-box border border-secondary">
                <div className="flex px-6 text-white">
                    <button
                        onClick={() => setModalOpen(false)}
                        className="flex h-8 w-8 items-center justify-center rounded-full shadow-glow"
                    >
                        <Close />
                    </button>
                    <h3 className="w-full text-center text-xl font-semibold">Filter Workouts</h3>
                </div>
                <div className="mt-4 flex flex-col gap-2 px-6">
                    <div className="flex w-full justify-end">
                        <button
                            onClick={handleCloseFilter}
                            className="btn-secondary h-8 w-20 rounded-full text-secondary shadow-glow"
                        >
                            Apply
                        </button>
                    </div>
                    <label className="flex w-full justify-between">
                        <span className="text-lg font-semibold text-white">Duration</span>
                    </label>
                    <Slider
                        className={'flex h-6 items-center'}
                        thumbClassName="bg-white w-6 h-6 rounded-full border-2 border-secondary"
                        defaultValue={[0, 500]}
                        value={[minDuration, maxDuration]}
                        onChange={(value) => {
                            setMinDuration(value[0]);
                            setMaxDuration(value[1]);
                        }}
                        renderThumb={({ key, ...props }, state) => <div key={key} {...props}></div>}
                        minDistance={1}
                        renderTrack={({ key, ...props }, state: { index }) => (
                            <div
                                key={key}
                                {...props}
                                className={`h-1 rounded-full ${state.index === 1 ? 'bg-secondary' : 'bg-neutral-content'}`}
                            />
                        )}
                        min={0}
                        max={60}
                        step={1}
                    />
                    <div className="mt-4 flex flex-col gap-4">
                        <label className="text-lg font-semibold text-white">Body Part</label>
                        <div className="grid grid-cols-4 gap-2">
                            {loadingExercises
                                ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((val) => (
                                      <div
                                          key={val}
                                          className="skeleton h-7 w-[90px] rounded-full bg-secondary/20"
                                      ></div>
                                  ))
                                : muscles.map((muscle, index) => (
                                      <button
                                          key={index}
                                          onClick={() => {
                                              if (bodyPart.includes(muscle.id)) {
                                                  setBodyPart(bodyPart.filter((item) => item !== muscle.id));
                                              } else {
                                                  setBodyPart([...bodyPart, muscle.id]);
                                              }
                                          }}
                                          className={`w-[90px] rounded-full border px-4 py-[6px] text-center text-sm ${
                                              bodyPart.includes(muscle.id)
                                                  ? 'border-secondary text-secondary shadow-glow'
                                                  : 'border-[#8E8E8E] text-[#8E8E8E]'
                                          }`}
                                      >
                                          {muscle.name}
                                      </button>
                                  ))}
                        </div>
                    </div>
                    <div className="mt-4 flex flex-col gap-4">
                        <label className="text-lg font-semibold text-white">Your Intensity Level</label>
                        <select
                            onChange={(e) => setIntensity(e.target.value)}
                            className="h-[40px] rounded-full border-white bg-black px-6 py-[10px] open:text-secondary"
                        >
                            <option value="">Select Intensity</option>
                            {Object.values(IntensityLevel).map((level) => (
                                <option key={level} value={level}>
                                    {level}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;

import { yupResolver } from '@hookform/resolvers/yup';
import ShowPassword from 'icons/show-password';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import * as Yup from 'yup';
import { resetNewUser, setNewUser } from '../../redux/authSlice';
import { useLayout } from './layout';

interface IFormInput {
    full_name: string;
    username: string;
    email: string;
    password: string;
    repeatPassword: string;
}

const SignupPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { setPrevPath, setHideSlogan } = useLayout();
    const [showPassword, setShowPassword] = useState(false);

    const schema = Yup.object().shape({
        full_name: Yup.string().required('Full name is required'),
        username: Yup.string().required('Username is required'),
        email: Yup.string().email('Email is invalid').required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .matches(/[!+@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`),
        repeatPassword: Yup.string().required('Password is required'),
    });

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = useCallback(
        async (values: IFormInput) => {
            if (values.password !== values.repeatPassword) {
                setError('repeatPassword', { type: 'custom', message: 'Passwords do not match' });
                return;
            }
            dispatch(setNewUser(values));
            navigate('/signup/set-profile');
        },
        [dispatch, navigate, setError]
    );

    useEffect(() => {
        setPrevPath('/auth');
        setHideSlogan(true);
    }, [dispatch, setPrevPath]);

    useEffect(() => {
        dispatch(resetNewUser());
    }, [dispatch]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-control flex h-full w-full flex-col items-center justify-start gap-[32px] px-[24px]"
        >
            <p className="label text-2xl text-white">Create New Account</p>
            <div className="flex w-full flex-col gap-3">
                <div className={'relative'}>
                    <input
                        type="text"
                        placeholder="Full Name"
                        className={`input input-bordered w-full rounded-full text-xs text-white focus:bg-[#131313] focus:outline-none ${errors.full_name ? 'border-warning' : ''}`}
                        {...register('full_name')}
                    />
                    <div className="absolute flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.full_name && <span className="text-xs">{errors.full_name?.message}</span>}
                    </div>
                </div>
                <div className={'relative'}>
                    <input
                        type="text"
                        placeholder="Username"
                        className={`input input-bordered w-full rounded-full text-xs text-white focus:bg-[#131313] focus:outline-none ${errors.username ? 'border-warning' : ''}`}
                        autoComplete="username"
                        {...register('username')}
                    />
                    <div className="absolute flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.username && <span className="text-xs">{errors.username?.message}</span>}
                    </div>
                </div>
                <div className={'relative'}>
                    <input
                        type="text"
                        placeholder="Email"
                        className={`input input-bordered w-full rounded-full text-xs text-white focus:bg-[#131313] focus:outline-none ${errors.email ? 'border-warning' : ''}`}
                        autoComplete="email"
                        {...register('email')}
                    />
                    <div className="absolute flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.email && <span className="text-xs">{errors.email?.message}</span>}
                    </div>
                </div>
                <div className={'relative'}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className={`input input-bordered relative w-full rounded-full text-xs text-white focus:bg-[#131313] focus:outline-none ${errors.password ? 'border-warning' : ''}`}
                        autoComplete="new-password"
                        {...register('password')}
                    />
                    <label className="swap swap-rotate absolute right-2 top-2">
                        <input
                            type="checkbox"
                            checked={showPassword}
                            className="hidden"
                            onChange={() => setShowPassword(!showPassword)}
                        />
                        <ShowPassword className={`swap-on h-10 w-6 fill-current text-secondary`} />
                        <ShowPassword className={`swap-off h-10 w-6 fill-current`} />
                    </label>
                    <div className="absolute flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.password && <span className="text-xs">{errors.password?.message}</span>}
                    </div>
                </div>
                <div className={'relative'}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Repeat Password"
                        className={`input input-bordered w-full rounded-full text-xs text-white focus:bg-[#131313] focus:outline-none ${errors.repeatPassword ? 'border-warning' : ''}`}
                        autoComplete="repeat-password"
                        {...register('repeatPassword')}
                    />
                    <p className="text-[10px]">
                        Min 8 characters, number, uppercase, lowercase & special symbol (Aa!@$%){' '}
                    </p>
                    <label className="swap swap-rotate absolute right-2 top-2">
                        <input
                            type="checkbox"
                            checked={showPassword}
                            className="hidden"
                            onChange={() => setShowPassword(!showPassword)}
                        />
                        <ShowPassword className={`swap-on h-10 w-6 fill-current text-secondary`} />
                        <ShowPassword className={`swap-off h-10 w-6 fill-current`} />
                    </label>
                    <div className="absolute flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.repeatPassword && <span className="text-xs">{errors.repeatPassword?.message}</span>}
                    </div>
                </div>
                <div className="w-full text-center text-xs text-[#979797]">
                    Already have an Account?{' '}
                    <Link to={'/auth/signin'} className="text-secondary underline">
                        Sign In
                    </Link>
                </div>
            </div>
            <div className="flex w-1/2 justify-center">
                <button
                    type="submit"
                    className={`h-[40px] w-[150px] rounded-full border border-white bg-black text-white`}
                >
                    Create
                </button>
            </div>
        </form>
    );
};

export default SignupPage;

import { yupResolver } from '@hookform/resolvers/yup';
import ShowPassword from 'icons/show-password';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import * as yup from 'yup';
import { changePasswordConfirm } from '../../redux/actions/userActions';
import { confirmNotification, warningNotification } from '../../redux/notificationSlice';
import { useLayout } from './layout';

interface IFormInput {
    email: string;
    code: string;
    newPassword: string;
    newPasswordRepeat: string;
}

const ChangePasswordPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { setPrevPath } = useLayout();
    const { loading, email, responseMessage, error, active } = useSelector(
        (state: RootState) => state.user.resetPasswordState
    );
    const [showPassword, setShowPassword] = useState(false);

    const schema: yup.ObjectSchema<IFormInput> = yup.object().shape({
        email: yup.string().required('Email/Username is required'),
        code: yup.string().required('Code is required'),
        newPassword: yup.string().required('Password is required'),
        newPasswordRepeat: yup
            .string()
            .oneOf([yup.ref('newPassword')], 'Passwords must match')
            .required('Repeat Password is required'),
    });

    useEffect(() => {
        setPrevPath('/auth');
    }, [setPrevPath]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            email: email,
        },
        resolver: yupResolver<IFormInput>(schema) as any,
    });

    const onSubmit: SubmitHandler<IFormInput> = useCallback(
        (data) => {
            dispatch(changePasswordConfirm(data));
        },
        [dispatch]
    );

    useEffect(() => {
        // !active && navigate('/auth');
    }, [active, navigate]);

    useEffect(() => {
        dispatch(confirmNotification(responseMessage));
    }, [responseMessage, dispatch]);

    useEffect(() => {
        dispatch(warningNotification(error));
    }, [error, dispatch]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-control flex h-full w-full flex-col items-center justify-center"
        >
            <p className="label text-xl font-semibold text-white">Confirm code from email</p>
            <div className="flex w-10/12 flex-col justify-evenly">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Email"
                        autoComplete="username"
                        className={`input input-bordered relative w-full rounded-full focus:outline-secondary ${errors.email ? 'border-warning' : ''}`}
                        disabled={true}
                        {...register('email')}
                    />
                    <div className="flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.email && <span className="text-xs">{errors.email?.message}</span>}
                    </div>
                </div>
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Code"
                        className={`input input-bordered relative w-full rounded-full focus:outline-secondary ${errors.email ? 'border-warning' : ''}`}
                        {...register('code')}
                    />
                    <div className="flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.email && <span className="text-xs">{errors.email?.message}</span>}
                    </div>
                </div>
                <div className={'relative flex w-full flex-col items-center'}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className={`input input-bordered w-full rounded-full focus:outline-secondary ${errors.newPassword ? 'border-warning' : ''}`}
                        autoComplete="new-password"
                        {...register('newPassword')}
                    />
                    <label className="swap swap-rotate absolute right-2 top-2">
                        <input
                            type="checkbox"
                            checked={showPassword}
                            className="hidden"
                            onChange={() => setShowPassword(!showPassword)}
                        />
                        <ShowPassword className={`swap-on h-10 w-7 fill-current text-secondary`} />
                        <ShowPassword className={`swap-off h-10 w-7 fill-current`} />
                    </label>
                    <div className="flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.newPassword && <span className="text-xs">{errors.newPassword?.message}</span>}
                    </div>
                </div>
                <div className={'relative flex w-full flex-col items-center'}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Repeat Password"
                        className={`input input-bordered w-full rounded-full focus:outline-secondary ${errors.newPasswordRepeat ? 'border-warning' : ''}`}
                        autoComplete="repeat-password"
                        {...register('newPasswordRepeat')}
                    />
                    <label className="swap swap-rotate absolute right-2 top-2">
                        <input
                            type="checkbox"
                            checked={showPassword}
                            className="hidden"
                            onChange={() => setShowPassword(!showPassword)}
                        />
                        <ShowPassword className={`swap-on h-10 w-7 fill-current text-secondary`} />
                        <ShowPassword className={`swap-off h-10 w-7 fill-current`} />
                    </label>
                    <div className="flex h-4 w-full items-center justify-end px-4 text-sm text-error">
                        {errors.newPasswordRepeat && (
                            <span className="text-xs">{errors.newPasswordRepeat?.message}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex w-full justify-center p-2">
                <button
                    type="submit"
                    className={`btn btn-outline w-8/12 rounded-full hover:btn-secondary`}
                    disabled={loading}
                >
                    {loading ? 'Sending...' : 'Send'}
                </button>
            </div>
        </form>
    );
};

export default ChangePasswordPage;

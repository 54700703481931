import React from 'react';

interface PrimaryButtonProps {
    title: string;
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    maxWidth?: number;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ title, className, onClick, disabled, maxWidth = 150 }) => {
    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };

    return (
        <button
            onClick={handleClick}
            disabled={disabled}
            className={`${className || ''} flex h-[40px] w-full items-center justify-center gap-[16px] rounded-full border border-white text-[16px] text-white transition-opacity duration-75 ${
                disabled ? 'cursor-not-allowed bg-gray-400 opacity-50' : 'cursor-pointer bg-black active:opacity-75'
            } ${maxWidth ? `max-w-[${maxWidth}px]` : 'max-w-[150px]'}`}
        >
            {title}
        </button>
    );
};

export default PrimaryButton;

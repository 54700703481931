import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    changePassword,
    changePasswordConfirm,
    completeBilling,
    createBilling,
    updateProfilePicture,
    updateProgramProgress,
    updateUser,
} from './actions/userActions';
import { warningNotification } from './notificationSlice';

export interface UserState {
    clientSecret?: string;
    subscriptionId?: string;
    resetPasswordState: ResetPasswordState;
    loading: boolean;
    error: string | null;
}

export interface ResetPasswordState {
    loading: boolean;
    error: string | null;
    responseMessage: string;
    email: string;
    active: boolean;
}

const initialResetPasswordState: ResetPasswordState = {
    loading: false,
    error: null,
    responseMessage: '',
    email: '',
    active: false,
};

const initialState: UserState = {
    loading: false,
    resetPasswordState: initialResetPasswordState,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setResetPasswordEmail(state, action: PayloadAction<string>) {
            state.resetPasswordState.email = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            //Create Billing
            .addCase(createBilling.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createBilling.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                console.log(action.payload);
                state.subscriptionId = action.payload.results.subscriptionId;
                state.clientSecret = action.payload.results.clientSecret;
                state.error = null;
            })
            .addCase(createBilling.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            //Complete Billing
            .addCase(completeBilling.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(completeBilling.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(completeBilling.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            //Update User
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            //Update Profile Picture
            .addCase(updateProfilePicture.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProfilePicture.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateProfilePicture.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            //Change Password
            .addCase(changePassword.pending, (state, action: PayloadAction<any>) => {
                state.resetPasswordState.loading = true;
                state.resetPasswordState.error = null;
            })
            .addCase(changePassword.fulfilled, (state, action: PayloadAction<any>) => {
                state.resetPasswordState.loading = false;
                state.resetPasswordState.responseMessage = action.payload.message as string;
                state.resetPasswordState.active = true;
                state.resetPasswordState.error = null;
            })
            .addCase(changePassword.rejected, (state, action: PayloadAction<any>) => {
                state.resetPasswordState.loading = false;
                state.resetPasswordState.error = action.payload as string;
            })

            //Change Password Confirm
            .addCase(changePasswordConfirm.pending, (state, action: PayloadAction<any>) => {
                state.resetPasswordState.loading = true;
                state.resetPasswordState.error = null;
            })
            .addCase(changePasswordConfirm.fulfilled, (state, action: PayloadAction<any>) => {
                state.resetPasswordState.loading = false;
                state.resetPasswordState.responseMessage = action.payload.message as string;
                state.resetPasswordState.active = false;
                state.resetPasswordState.error = null;
            })
            .addCase(changePasswordConfirm.rejected, (state, action: PayloadAction<any>) => {
                state.resetPasswordState.loading = false;
                state.resetPasswordState.error = action.payload as string;
            })

            //Update Program Progress
            .addCase(updateProgramProgress.pending, (state, action: PayloadAction<any>) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProgramProgress.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateProgramProgress.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { setResetPasswordEmail } = userSlice.actions;

export default userSlice.reducer;

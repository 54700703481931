import WelcomeImage from 'assets/img/signup/welcome.png';
import { useLayout } from 'pages/auth/layout';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { registerUser } from '../../redux/actions/authActions';
import { warningNotification } from '../../redux/notificationSlice';
import PrimaryButton from '../../components/PrimaryButton';
import { getPrices } from '../../redux/actions/stripeActions';
import LoadingSpinner from '../../components/LoadingSpinner';

const WelcomePage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { setPrevPath } = useLayout();
    const { newUser, error } = useSelector((state: RootState) => state.auth);

    const [loading, setLoading] = useState('');

    useEffect(() => {
        setPrevPath('/signup/weight-selection');
    }, []);

    const submit = async () => {
        setLoading('Setting up your account...');

        const creationResult = await dispatch(registerUser());

        if (registerUser.fulfilled.match(creationResult)) {
            setLoading('Redirecting to billing...');
            await dispatch(getPrices());
            setLoading(null);
            navigate('/signup/choose-plan');
        } else {
            setLoading(null);
        }
    };

    useEffect(() => {
        if (error !== 'User already exists') dispatch(warningNotification(error));
        if (error && error.includes('User already exists'))
            dispatch(warningNotification('User already exists. Please sign in'));
    }, [dispatch, error]);

    if (!loading) {
        return (
            <div className="mt-6 flex flex-col items-center text-white">
                <div className="relative flex h-[16.2rem] w-full items-end justify-center overflow-hidden">
                    <img src={WelcomeImage} className="absolute h-[308px] w-[308px] object-contain" alt="Welcome" />
                </div>
                <div className="flex flex-col items-center gap-16">
                    <div className="flex flex-col items-center justify-center gap-8">
                        <span className="text-2xl text-white">Welcome</span>
                        <div className="flex flex-col items-center justify-center gap-4 leading-5">
                            <p className="text-center">
                                Use This App At A <span className="text-secondary">Gym Or At Home</span>
                                <br />
                                If You Have Dumbbells.
                            </p>
                            <p className="text-center">
                                SweatSonic Replaces You Need For A <br />
                                <span className="text-secondary">Personal Trainer</span>.
                            </p>
                            <p className="text-center">
                                Just Put On Your Headphones And <br />{' '}
                                <span className="text-secondary">Follow The Music</span>.
                            </p>
                        </div>
                    </div>
                    {error ? (
                        <Link
                            to={'/auth'}
                            className={`flex h-[40px] w-[150px] items-center justify-center rounded-full border border-white bg-black ${loading ? 'disabled' : ''} rounded-full`}
                        >
                            Go Back
                        </Link>
                    ) : (
                        <PrimaryButton title="Continue" onClick={() => submit()} />
                    )}
                </div>
            </div>
        );
    } else {
        return <LoadingSpinner className="pt-48" message={loading} />;
    }
};

export default WelcomePage;

import { Clock } from 'icons';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { secondsToMinutes } from 'util/time';
import BackIcon from '../../assets/img/icons/back-icon.png';
import { getWorkoutById, getWorkoutVideos } from '../../redux/actions/workoutActions';
import { useAppDispatch } from '../../redux/hooks';
import { clearWatchedVideos } from '../../redux/workoutSlice';

const WorkoutPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { loading, loadingVideos, workout: current } = useSelector((state: RootState) => state.workouts);

    const handleSelectVideo = useCallback(
        (index: number) => {
            navigate(`/workout/${id}/video/${index}`);
        },
        [id, navigate]
    );

    const handleBack = useCallback(() => {
        navigate('/');
    }, [navigate]);

    useEffect(() => {
        dispatch(clearWatchedVideos());
        if (current?.id.toString() === id) return;
        dispatch(getWorkoutById({ id }));
        dispatch(getWorkoutVideos({ id }));
    }, [current, dispatch, id]);

    return (
        <div className="h-full w-full font-['Poppins-Med'] lg:mt-16">
            <div className="flex h-1/4 w-full space-x-2 rounded-xl from-transparent to-slate-900 p-4 lg:h-2/6 lg:bg-gradient-to-t">
                <button onClick={handleBack} className="flex h-full w-1/12 items-start justify-center lg:hidden">
                    <img src={BackIcon} alt="Back Icon" className="h-4" />
                </button>
                <div className="flex h-full w-4/12 justify-end lg:w-4/12">
                    <img
                        src={current?.workout_cover_image}
                        alt="workout"
                        className="rounded-xl object-cover object-[center_25%] lg:w-full"
                    />
                </div>
                <div className="flex h-full w-6/12 flex-col">
                    <p className="pb-4 font-bold uppercase text-white lg:text-4xl">{current?.title}</p>
                    <div className="text-sm text-white lg:text-xl">Description</div>
                    <p className="text-xs font-extralight lg:text-lg">{current?.description}</p>
                </div>
            </div>
            <div className="flex h-3/4 flex-col justify-evenly">
                <div className="flex w-full justify-evenly p-2 lg:h-auto">
                    <div className="flex flex-col items-center justify-center p-2">
                        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.5 0H12.5V6L8.5 10L12.5 14V20H0.5V14L4.5 10L0.5 6V0ZM10.5 14.5L6.5 10.5L2.5 14.5V18H10.5V14.5ZM6.5 9.5L10.5 5.5V2H2.5V5.5L6.5 9.5ZM4.5 4H8.5V4.75L6.5 6.75L4.5 4.75V4Z"
                                fill="#05BEF8"
                            />
                        </svg>
                        <p className="mt-2 text-xs text-white">
                            {loading ? '...' : (current?.workout_duration % 60) + ' min'}
                        </p>
                        <p className="text-xs">Duration</p>
                    </div>
                    <div className="divider divider-neutral divider-horizontal py-4" />
                    <div className="flex flex-col items-center justify-center p-2">
                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.252 12.7272C16.252 17.02 12.8941 20.4999 8.75195 20.4999C4.60982 20.4999 1.25195 17.02 1.25195 12.7272C1.25195 11.1614 1.71266 8.5397 3.12695 7.19994L5.52536 10.4405C5.65319 10.6132 5.93688 10.5206 5.94073 10.3058C5.97555 8.36646 6.2955 2.93882 8.75195 1.49994C8.75195 1.49994 16.252 8.43445 16.252 12.7272Z"
                                stroke="#05BEF8"
                                strokeWidth="2"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <p className="mt-2 text-xs text-white">{current?.difficulty ?? '...'}</p>
                        <p className="text-xs">Difficulty</p>
                    </div>
                    <div className="divider divider-neutral divider-horizontal py-4" />
                    <div className="flex flex-col items-center justify-center p-2">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.1318 15.3196L15.6472 16.7882C16.6076 15.5626 17.1946 14.1469 17.376 12.6783H15.2203C15.0709 13.5975 14.7081 14.4955 14.1318 15.3196ZM9.9805 17.8553V19.9894C11.4639 19.8098 12.9045 19.2393 14.1425 18.2884L12.6057 16.767C11.8054 17.3376 10.9089 17.7073 9.9805 17.8553ZM17.376 10.5652C17.1859 9.06567 16.5884 7.64525 15.6472 6.45536L14.1318 7.95563C14.7081 8.74802 15.0709 9.64606 15.2203 10.5652M12.7018 4.80718L7.84616 0V3.24353C3.64151 3.76123 0.375977 7.31115 0.375977 11.6218C0.375977 15.9324 3.63084 19.4823 7.84616 20V17.8658C4.8154 17.3587 2.51031 14.7596 2.51031 11.6218C2.51031 8.48389 4.8154 5.88484 7.84616 5.37771V9.50872L12.7018 4.80718Z"
                                fill="#05BEF8"
                            />
                        </svg>
                        <p className="mt-2 text-xs text-white">{current?.rounds ?? '...'}</p>
                        <p className="text-xs">Rounds</p>
                    </div>
                </div>
                <button
                    onClick={() => navigate(`/workout/${id}/video/0`)}
                    className="w-11/12 self-center rounded-full border border-secondary/25 bg-black/75 p-2 text-sm font-light tracking-wider text-secondary shadow-glow hover:shadow-glow-2"
                >
                    Start Now
                </button>
                {/* <div className="flex items-center justify-between p-4">
                    <div className="flex items-center">
                        <button className="p-2">
                            <Heart />
                        </button>
                        <span className="text-xs text-white">Favorite Workout</span>
                    </div>
                    <button className="shadow-glow hover:shadow-glow-2 flex h-7 w-20 items-center justify-center rounded-full border border-secondary p-2">
                        <Share className="p-1" />
                        <span className="text-xs font-bold text-secondary">Share</span>
                    </button>
                </div> */}
                <div className="mt-6 flex h-5/6 w-full flex-col items-center space-y-4 overflow-y-scroll pb-16 lg:pb-0">
                    <div className="flex w-full justify-between px-4 text-sm text-white lg:text-lg">
                        <span className="flex w-1/5 items-center justify-start lg:w-2/12">Exercise</span>
                        <div className="flex w-4/5 items-center justify-end lg:w-10/12">
                            <span className="mr-1">Duration</span>
                            <Clock />
                        </div>
                    </div>
                    {loadingVideos
                        ? [0, 1, 2, 3, 4].map((val) => (
                              <div key={val} className="flex h-full w-full px-4">
                                  <div className="skeleton w-1/5 bg-secondary/20 lg:w-1/12"></div>
                                  <div className="ml-2 flex h-full w-4/5 flex-col items-start justify-center lg:w-11/12">
                                      <div className="skeleton h-2 w-24 bg-secondary/20"></div>
                                      <div className="skeleton mt-2 h-2 w-6 bg-secondary/20"></div>
                                  </div>
                              </div>
                          ))
                        : current &&
                          current.videos &&
                          current.videos.map((video, index) => (
                              <div
                                  onClick={() => handleSelectVideo(index)}
                                  id={index.toString()}
                                  key={index}
                                  className={`flex w-full cursor-pointer rounded-lg px-4 hover:shadow-glow`}
                              >
                                  <img
                                      className="w-1/5 object-cover lg:w-1/12"
                                      src={video.video_thumbnail_image}
                                      alt="video thumbnail"
                                  />
                                  <div className="flex w-4/5 justify-between lg:w-11/12">
                                      <div className="flex flex-col items-start justify-center p-2">
                                          <span className="text-sm text-white">{video.title}</span>
                                          <span className="text-xs">{video.trainer_name}</span>
                                      </div>
                                      <div className="w-1/5 content-center text-end text-xs">
                                          <span>{secondsToMinutes(video.video_duration)} MIN</span>
                                      </div>
                                  </div>
                              </div>
                          ))}
                </div>
            </div>
        </div>
    );
};

export default WorkoutPage;

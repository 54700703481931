import { createAsyncThunk } from '@reduxjs/toolkit';
import { SetupIntent } from '@stripe/stripe-js';
import { connection } from 'util/axios';

export const createBilling = createAsyncThunk(
    'user/create-billing',
    async (props: { priceId: string }, { rejectWithValue }) => {
        try {
            const { priceId } = props;
            const response = await connection.post('/user/create-billing', { priceId });

            if (response.data.error) {
                return rejectWithValue(response?.data?.message || 'Error creating billing');
            }

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const completeBilling = createAsyncThunk(
    'user/complete-billing',
    async (setupIntent: SetupIntent, { rejectWithValue }) => {
        try {
            const response = await connection.post('/user/complete-billing');
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const updateProfilePicture = createAsyncThunk(
    'user/picture-update',
    async (props: { file: File }, { rejectWithValue }) => {
        try {
            const { file } = props;
            const formData = new FormData();
            formData.append('file', file);

            const response = await connection.post('/user/picture/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const updateUser = createAsyncThunk(
    'user/update',
    async (
        props: {
            id: string;
            data: {
                name?: string;
                bio?: string;
                location?: string;
                gender?: string;
                dob?: string;
                weight?: string;
                height?: string;
                phone?: string;
                activity?: string;
                username?: string;
                new_password?: false;
                private_profile?: false;
                image?: string;
                image_type?: string;
            };
        },
        { rejectWithValue }
    ) => {
        try {
            const { id, data } = props;
            const response = await connection.post(`/user/update/${id}`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const changePassword = createAsyncThunk(
    'user/change-password',
    async (data: { email: string }, { rejectWithValue }) => {
        try {
            const response = await connection.post('/user/change-password', data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const changePasswordConfirm = createAsyncThunk(
    'user/change-password-confirm',
    async (data: { email: string; code: string; newPassword: string }, { rejectWithValue }) => {
        try {
            const response = await connection.post('/user/change-password/confirm', data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const updateProgramProgress = createAsyncThunk(
    'user/update-program-progress',
    async (data: { id: string; updateProgramStatus: number; programProgress: number }, { rejectWithValue }) => {
        try {
            const { id, updateProgramStatus, programProgress } = data;
            const response = await connection.post(`/user/program/${id}/update`, {
                update_program_status: updateProgramStatus,
                program_progress: programProgress,
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

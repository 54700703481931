import { Clock, Fire } from 'icons';
import Close from 'icons/close';
import Filter from 'icons/filter';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { getAllWorkouts } from '../../redux/actions/workoutActions';
import WorkoutsFilterModal from './WorkoutsFilterModal';

const WorkoutsLibrary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { allWorkouts, loading } = useSelector((state: RootState) => state.workouts);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [modalOpen, setModalOpen] = useState(false);
    const [minDuration, setMinDuration] = useState(0);
    const [maxDuration, setMaxDuration] = useState(60);
    const [bodyPart, setBodyPart] = useState<string[]>([]);
    const [intensity, setIntensity] = useState<string>();

    const handleSelectElement = useCallback(
        (element: any) => {
            navigate('/workout/' + element.id);
        },
        [navigate]
    );

    const handleOpenFilter = useCallback(() => {
        setModalOpen(true);
    }, [dispatch]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        dispatch(getAllWorkouts({ page, take, search: debouncedSearch }));
    }, [dispatch, page, take, debouncedSearch]);

    const handleCloseFilter = useCallback(() => {
        setModalOpen(false);
        dispatch(
            getAllWorkouts({
                page,
                take,
                search: debouncedSearch,
                min_duration: minDuration * 60,
                max_duration: maxDuration * 60,
                categories: bodyPart,
                intensity: intensity,
            })
        );
    }, [bodyPart, debouncedSearch, dispatch, maxDuration, minDuration, page, take, intensity]);

    return (
        <div className="mb-16 flex w-full flex-col gap-6 overflow-y-scroll pt-4 md:h-5/6 lg:mb-0">
            <div className="z-20 flex items-center justify-center bg-black px-6">
                <div className="relative h-[40px] w-full">
                    <input
                        type="text"
                        placeholder="Search"
                        className="w-full rounded-full border bg-black py-2 pl-6 pr-4 focus:outline-none"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-3 top-2">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
                <button
                    onClick={handleOpenFilter}
                    className="ml-2 flex h-[40px] items-center gap-[4px] rounded-full border border-[#979797] bg-black px-4 py-2 text-[#979797] hover:border-secondary hover:text-secondary hover:shadow-glow"
                >
                    <Filter />
                    <span>Filter</span>
                </button>
                <WorkoutsFilterModal
                    modalOpen={modalOpen}
                    minDuration={minDuration}
                    maxDuration={maxDuration}
                    bodyPart={bodyPart}
                    setModalOpen={setModalOpen}
                    setMinDuration={setMinDuration}
                    setMaxDuration={setMaxDuration}
                    setBodyPart={setBodyPart}
                    setIntensity={setIntensity}
                    handleCloseFilter={handleCloseFilter}
                />
            </div>
            <div className="grid grid-cols-2 gap-4 overflow-y-scroll px-6 lg:grid-cols-4">
                {loading ? (
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((val) => (
                        <div key={val} className="skeleton h-72 w-full rounded-3xl bg-secondary/20"></div>
                    ))
                ) : allWorkouts.length === 0 ? (
                    <p>No reuslts...</p>
                ) : (
                    allWorkouts.map((workout, index) => (
                        <div
                            onClick={() => handleSelectElement(workout)}
                            key={index}
                            className="card relative flex h-72 cursor-pointer items-center justify-end rounded-3xl border border-neutral-800 p-2 hover:border-secondary hover:shadow-glow"
                        >
                            <img
                                src={workout.cover_image}
                                alt={workout.title}
                                className="absolute inset-0 h-full w-full rounded-3xl object-cover"
                            />
                            <div className="absolute inset-0 z-0 h-full w-full rounded-3xl bg-gradient-to-t from-black to-transparent to-50%"></div>
                            <div className="card-actions flex-col items-center justify-end gap-4">
                                <p className="z-10 font-extrabold capitalize text-white">{workout.title}</p>
                                <div className="z-10 flex items-center gap-1 rounded-full border border-white bg-[#181818]/60 px-4 py-1 text-[10px] text-white">
                                    <div className="flex items-center justify-center gap-[2px]">
                                        <Fire />
                                        Difficulty: x {workout.difficulty}
                                    </div>
                                    <div className="flex items-center justify-center gap-[2px]">
                                        <Clock />
                                        {workout.workout_duration % 60} Min
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default WorkoutsLibrary;

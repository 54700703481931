import Close from 'icons/close';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slider';
import { AppDispatch, RootState } from 'redux/store';
import { getAllExercises, getAllMuscles, getAllTypes } from '../../redux/actions/exerciseActions';

export type FilterModalProps = {
    modalOpen: boolean;
    minDuration: number;
    maxDuration: number;
    bodyPart: string[];
    setModalOpen: (open) => void;
    setMinDuration: (minDuration: number) => void;
    setMaxDuration: (maxDuration: number) => void;
    setBodyPart: (bodyPart: string[]) => void;
    setType: (type: string) => void;
    setName: (name: string) => void;
    handleCloseFilter: () => void;
};

const FilterModal = ({
    modalOpen,
    maxDuration,
    minDuration,
    bodyPart,
    setModalOpen,
    setMaxDuration,
    setMinDuration,
    setBodyPart,
    setType,
    setName,
    handleCloseFilter,
}: FilterModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { muscles, types, exercises, loading: loadingExercises } = useSelector((state: RootState) => state.exercise);

    useEffect(() => {
        dispatch(getAllMuscles());
        dispatch(getAllTypes());
        dispatch(getAllExercises());
    }, []);

    return (
        <div className={`modal ${modalOpen ? 'modal-open' : ''}`}>
            <div className="modal-box border border-secondary">
                <button onClick={() => setModalOpen(false)} className="absolute right-2 top-2 p-2">
                    <Close />
                </button>
                <h3 className="text-center text-lg font-bold">Filter</h3>
                <div className="mt-4">
                    <label className="flex w-full justify-between">
                        <span>Duration Range</span>
                        <span>
                            {minDuration} - {maxDuration}
                        </span>
                    </label>
                    <Slider
                        className={'flex h-6 items-center'}
                        thumbClassName="bg-white w-6 h-6 rounded-full border-2 border-secondary"
                        defaultValue={[0, 60]}
                        value={[minDuration, maxDuration]}
                        onChange={(value) => {
                            setMinDuration(value[0]);
                            setMaxDuration(value[1]);
                        }}
                        renderThumb={({ key, ...props }, state) => <div key={key} {...props}></div>}
                        minDistance={1}
                        renderTrack={({ key, ...props }, state: { index }) => (
                            <div
                                key={key}
                                {...props}
                                className={`h-1 rounded-full ${state.index === 1 ? 'bg-secondary' : 'bg-neutral-content'}`}
                            />
                        )}
                        min={0}
                        max={60}
                        step={1}
                    />
                </div>
                <div className="mt-4">
                    <label>Body Part</label>
                    <div className="grid grid-cols-4 gap-2">
                        {loadingExercises
                            ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((val) => (
                                  <div key={val} className="skeleton h-8 w-full bg-secondary/20"></div>
                              ))
                            : muscles.map((muscle, index) => (
                                  <button
                                      key={index}
                                      onClick={() => {
                                          if (bodyPart.includes(muscle.id)) {
                                              setBodyPart(bodyPart.filter((item) => item !== muscle.id));
                                          } else {
                                              setBodyPart([...bodyPart, muscle.id]);
                                          }
                                      }}
                                      className={`btn btn-sm textarea-xs rounded-full ${
                                          bodyPart.includes(muscle.id) ? 'btn-secondary' : 'btn-outline'
                                      }`}
                                  >
                                      {muscle.name}
                                  </button>
                              ))}
                    </div>
                </div>
                <div className="mt-4 flex flex-col">
                    <label>Exercise Type</label>
                    <select onChange={(e) => setType(e.target.value)} className="input">
                        <option key={'all'} value={null}>
                            All
                        </option>
                        {types.map((type, index) => (
                            <option key={index} value={type.name}>
                                {type.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mt-4 flex flex-col">
                    <label>Exercise Name</label>
                    <select onChange={(e) => setName(e.target.value)} className="input">
                        <option key={'all'} value={null}>
                            All
                        </option>
                        {exercises.map((exercise, index) => (
                            <option key={index} value={exercise.name}>
                                {exercise.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="modal-action">
                    <button onClick={handleCloseFilter} className="btn btn-outline btn-secondary">
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;

import WeightSelectionImage from 'assets/img/signup/weight.png';
import { useLayout } from 'pages/signup/layout';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const WeightSelection = () => {
    const { setPrevPath, setFooterText, setStep } = useLayout();

    useEffect(() => {
        setPrevPath('/signup/introduction');
        setFooterText(null);
        setStep(null);
    });

    return (
        <div className="flex w-full flex-col items-center justify-start text-white">
            <div className="relative flex h-[18.2rem] w-full items-center justify-center overflow-hidden">
                <img src={WeightSelectionImage} className="absolute h-full w-full object-cover" alt="weight" />
            </div>

            <div className="flex flex-col items-center justify-center gap-8">
                <span className="text-2xl tracking-wide">Weight Selection</span>
                <div className="flex flex-col gap-4 text-base leading-5">
                    <div className="text-center">
                        <p>
                            <span className="text-secondary">Use Weights</span> That Challenge you
                        </p>
                        <p>
                            But That <span className="text-secondary">You Can Control</span>
                        </p>
                        <p>With Good Form.</p>
                    </div>
                    <div className="text-center">
                        <p>
                            <span className="text-secondary">Reduce Weight</span>
                        </p>
                        <p>When Necessary and </p>
                        <p>
                            <span className="text-secondary">Keep Going Until</span>
                        </p>
                        <p>Each Song is Complete.</p>
                    </div>
                </div>
                <Link
                    to={'/signup/welcome'}
                    className="mt-4 flex h-[40px] w-[150px] items-center justify-center rounded-full border border-white bg-black"
                >
                    Continue
                </Link>
            </div>
        </div>
    );
};

export default WeightSelection;

import Hero from 'components/Hero';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { RootState } from 'redux/store';
import desktopBackground from '../../assets/img/background/desktop.webp';

type ContextType = {
    prevPath: string | null;
    setPrevPath: (prevPath: string | null) => void;
    hideSlogan: boolean;
    setHideSlogan: (hide: boolean) => void;
};

const Layout = () => {
    const [prevPath, setPrevPath] = useState<string | null>(null);
    const [hideSlogan, setHideSlogan] = useState<boolean>(false);
    const authState = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (authState.access_token) {
            navigate('/');
        }
    }, [authState.access_token, navigate]);

    return (
        <div className="flex h-full w-full">
            <div className="flex h-dvh w-full flex-col items-center justify-between bg-smoke bg-cover font-['SF-Pro-Display'] tracking-wide lg:w-5/12">
                <div className="mt-12 w-full">
                    <Hero prevPath={prevPath} hideSlogan={hideSlogan} />
                </div>
                <div className="h-3/6 w-full overflow-auto">
                    <Outlet context={{ prevPath, setPrevPath, hideSlogan, setHideSlogan } satisfies ContextType} />
                </div>
                <div className="flex h-1/6 max-h-[120px] flex-col items-center justify-start">
                    <div className="divider my-0 w-screen"></div>
                    <p className="w-10/12 p-[5px] text-center text-xs leading-6 lg:w-2/3">
                        By being a member, you agree to our{' '}
                        <a href="https://sweatsonic.com/pages/terms-of-service" className="text-secondary underline">
                            Terms of Service
                        </a>
                        , and{' '}
                        <a href="https://sweatsonic.com/pages/privacy-policy" className="text-secondary underline">
                            Privacy Policy
                        </a>
                        , and{' '}
                        <a href="https://sweatsonic.com/pages/medical-disclaimer" className="text-secondary underline">
                            Medical Disclaimer
                        </a>
                    </p>
                </div>
            </div>
            <div className="relative hidden w-7/12 lg:inline-block">
                <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-black to-transparent" />
                <img src={desktopBackground} alt="background" className="h-full w-full object-cover" />
            </div>
        </div>
    );
};

export function useLayout() {
    return useOutletContext<ContextType>();
}

export default Layout;

import IntroductionImage from 'assets/img/signup/introduction.png';
import { useLayout } from 'pages/signup/layout';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const IntroductionPage = () => {
    const { setPrevPath, setStep, setFooterText } = useLayout();
    useEffect(() => {
        setFooterText('Get Ready For Progress');
        setPrevPath('/signup/set-purpose');
        setStep(null);
    });
    return (
        <div className="mt-12 flex h-full w-full flex-col items-center justify-center gap-12 text-white">
            <div className="flex flex-col items-center gap-8">
                <span className="text-2xl lg:text-4xl">Introduction</span>
                <div className="flex flex-col items-center justify-center leading-5 lg:text-xl">
                    <p>
                        <span className="text-secondary">Our Workouts</span>
                    </p>
                    <p>have an intensitiy rating</p>
                    <p>from X1 to X7.</p>
                    <p>
                        <span className="text-secondary">X1</span> workouts are
                    </p>
                    <p>
                        <span className="text-secondary">Quicker</span> and{' '}
                        <span className="text-secondary">Easier</span>
                    </p>
                </div>
                <img src={IntroductionImage} alt="Introduction" />
            </div>
            <div className="flex flex-col gap-4">
                <Link
                    to={'/signup/weight-selection'}
                    className="flex h-[40px] w-[150px] items-center justify-center rounded-full border border-white bg-black"
                >
                    Continue
                </Link>
                <Link
                    to="/signup/introduction"
                    className="p-4 text-center text-[10px] font-normal text-gray-600 underline lg:text-base"
                >
                    Skip
                </Link>
            </div>
        </div>
    );
};

export default IntroductionPage;

import LockedImage from 'assets/img/locked.png';
import { cn } from 'util/cn';

type PropsType = {
    className?: string;
};

const Locked = ({ className }: PropsType) => {
    return <img className={cn('', className)} src={LockedImage} alt="locked" />;
};

export default Locked;

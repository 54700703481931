export default function CloseIcon({ fillColor }) {
    return (
        <svg className="h-10" viewBox="0 0 34 34" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="33" height="33" rx="16.5" fill="#242424" fillOpacity="0.78" />
            <rect x="0.5" y="0.5" width="33" height="33" rx="16.5" stroke="#707070" />
            <path
                d="M15.8684 17L12.4341 20.4343L13.5655 21.5657L16.9998 18.1314L20.4341 21.5657L21.5655 20.4343L18.1311 17L21.5655 13.5657L20.4341 12.4343L16.9998 15.8686L13.5655 12.4343L12.4341 13.5657L15.8684 17Z"
                fill="white"
            />
        </svg>
    );
}

import { Clock, Fire } from 'icons';
import { Link } from 'react-router-dom';

export type WorkoutCard = {
    id: string;
    title: string;
    image: string;
    description?: { difficulty: number; duration: number };
};

export type WorkoutCarouselProps = {
    title?: string;
    loading: boolean;
    elements: WorkoutCard[];
    handleSelectElement: (element) => void;
};

const WorkoutsCarousel = (props: WorkoutCarouselProps) => {
    const { title, elements, handleSelectElement, loading } = props;

    return (
        <div className={`w-full overflow-y-clip lg:h-2/3`}>
            {loading ? (
                <div className="flex flex-col gap-6 overflow-x-scroll pb-6">
                    <div className="flex items-center pl-4">
                        <div className="skeleton h-[29px] w-2/3 bg-secondary/10"></div>
                    </div>
                    <div className="flex gap-4 pl-6">
                        <div className={`skeleton h-[250px] w-[181px] bg-secondary/10`}></div>
                        <div className={`skeleton h-[250px] w-[181px] bg-secondary/10`}></div>
                    </div>
                </div>
            ) : (
                <div className="flex h-full w-full flex-col items-center justify-center gap-6 border-b border-[#313131]/50 pb-6">
                    {title && (
                        <div className="flex h-[29px] w-full justify-between px-6">
                            <h1 className="text-2xl text-white">{title}</h1>
                            <Link to="/library/workouts" className="flex items-center underline">
                                View All
                            </Link>
                        </div>
                    )}
                    <div className="carousel w-full snap-x snap-proximity scroll-pl-6 gap-4 py-2 pl-6 lg:overflow-x-scroll">
                        {elements.map((element, index) => (
                            <div
                                onClick={() => handleSelectElement(element)}
                                id={index.toString()}
                                key={index}
                                className={`carousel-item flex cursor-pointer items-center justify-center lg:w-2/12`}
                            >
                                <div
                                    className="card relative h-[250px] w-[181px] rounded-3xl hover:shadow-glow"
                                    style={{
                                        backgroundImage: `url(${element.image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                    <div className="absolute h-full w-full rounded-3xl bg-gradient-to-t from-black to-transparent to-40%"></div>
                                    <div className="card-body relative z-10 items-center justify-end p-4">
                                        <h2 className="font-extrabold capitalize tracking-wider text-white">
                                            {element.title}
                                        </h2>
                                        {element.description && (
                                            <div className="card-actions flex-col items-center justify-end text-white">
                                                <div className="flex items-center space-x-2 rounded-full border border-white bg-black/50 px-4 py-1">
                                                    <div className="flex items-center gap-[2px]">
                                                        <Fire />
                                                        <p className="text-[10px]">X{element.description.difficulty}</p>
                                                    </div>
                                                    <div className="flex items-center gap-[2px]">
                                                        <Clock />
                                                        <p className="text-[10px]">
                                                            {element.description.duration % 60} Min
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default WorkoutsCarousel;

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { completeBilling } from '../redux/actions/userActions';
import { warningNotification } from '../redux/notificationSlice';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            setIsProcessing(true);

            const { setupIntent, error } = await stripe.confirmSetup({
                elements,
                confirmParams: {},
                redirect: 'if_required',
            });

            if (error) {
                dispatch(warningNotification(error.message));
            } else {
                await dispatch(completeBilling(setupIntent));
            }

            setIsProcessing(false);
            navigate('/signup/set-profile-picture');
        },
        [dispatch, elements, stripe]
    );
    return (
        <form onSubmit={handleSubmit} className="form-control flex w-full flex-col items-center justify-center">
            <p className="mb-6 text-[24px] tracking-wide text-white">Payment Details</p>
            <PaymentElement className="w-full" />
            <button
                disabled={isProcessing}
                type="submit"
                className="mt-4 h-[40px] w-full rounded-full border border-white bg-black text-white"
            >
                {isProcessing ? 'Processing...' : 'Start Your Free Trial'}
            </button>
        </form>
    );
};

export default CheckoutForm;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';
import { RootState } from '../store';

export const login = createAsyncThunk(
    'auth/login',
    async (props: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const data = {
                email: props.email,
                password: props.password,
            };
            const response = await connection.post(`/auth/login`, data);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Login failed');
        }
    }
);

export const registerUser = createAsyncThunk('auth/register', async (_, { rejectWithValue, getState }) => {
    try {
        const state: RootState = await getState();
        const newUserData = state?.auth?.newUser;
        const provider = state?.auth?.identity_provider;

        if (provider === 'google' || provider === 'apple') {
            const response = await connection.post(`/auth/oauth-register`, {
                provider: provider,
                token: state?.auth?.oauth_id_token,
                form: newUserData,
            });
            return {
                access_token: response.data.access_token,
                refresh_token: response.data.access_token,
            };
        } else {
            const response = await connection.post(`/auth/register`, {
                gender: newUserData?.gender,
                full_name: newUserData?.full_name,
                password: newUserData?.password,
                username: newUserData?.username,
                email: newUserData?.email,
                date_of_birth: newUserData?.date_of_birth,
                activity_level: newUserData?.activity_level,
                goal: newUserData?.goal,
                workout_length: newUserData?.workout_length,
                weight: newUserData?.weight,
                height: newUserData?.height,
                profile_picture: newUserData?.profile_picture,
                stripe_id: newUserData?.stripe_id,
                purpose: newUserData?.purpose,
            });
            return response.data.results;
        }

        return {};
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Register failed');
    }
});

export const getCurrentUser = createAsyncThunk('auth/current', async (_, { rejectWithValue }) => {
    try {
        const response = await connection.get(`/auth/current/user`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Fetch failed');
    }
});

export const oauthGoogleLogin = createAsyncThunk(
    'auth/oauth-google',
    async (data: { code: string }, { rejectWithValue }) => {
        try {
            const response = await connection.post(`/auth/oauth-code`, {
                code: data.code,
            });

            const token = response.data?.results?.id_token;

            if (token) {
                // Code flow was successful
                const response = await connection.post(`/auth/oauth-login`, {
                    provider: 'google',
                    token,
                });

                if (response.data?.status) {
                    return {
                        ...response.data,
                        oauth_id_token: token,
                    };
                } else {
                    return rejectWithValue('Google oAuth failed');
                }
            } else {
                return rejectWithValue('Google oAuth failed');
            }

            return '';
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'oAuth failed');
        }
    }
);

export const oauthAppleLogin = createAsyncThunk(
    'auth/oauth-apple',
    async (data: { tokenId: string }, { rejectWithValue }) => {
        try {
            const response = await connection.post(`/auth/oauth-login`, {
                provider: 'apple',
                token: data.tokenId,
            });

            if (response.data?.status) {
                return {
                    ...response.data,
                    oauth_id_token: data.tokenId,
                };
            } else {
                return rejectWithValue('Apple oAuth failed');
            }
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'oAuth failed');
        }
    }
);

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../redux/actions/authActions';
import { logout } from '../../redux/authSlice';
import { AppDispatch, RootState } from '../../redux/store';

const ProfilePage: React.FC = () => {
    const { current } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getCurrentUser());
    }, [dispatch]);

    const handleLogout = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    if (!current) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex w-full flex-col items-start justify-evenly p-4 lg:pt-24">
            <div className="flex w-full items-center justify-between px-4">
                <div className="flex items-center justify-center">
                    <div className="avatar">
                        <div className="w-14 rounded-full border border-white">
                            {current?.profile_image ? (
                                <img
                                    src={current.profile_image}
                                    alt={`${current.user_name}'s profile`}
                                    className="h-full w-full rounded-full object-cover"
                                />
                            ) : (
                                <div className="flex h-full items-center justify-center">
                                    <svg
                                        className="h-10 w-10"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M12.2496 12C9.7826 12 7.7666 9.985 7.7666 7.517C7.7666 5.049 9.7826 3 12.2496 3C14.7166 3 16.7326 5.015 16.7326 7.483C16.7326 9.951 14.7166 12 12.2496 12Z"
                                            fill="#8E8E8E"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M19.25 21H5.25C4.7 21 4.25 20.55 4.25 20V19C4.25 16.8 6.05 15 8.25 15H16.25C18.45 15 20.25 16.8 20.25 19V20C20.25 20.55 19.8 21 19.25 21Z"
                                            fill="#8E8E8E"
                                        />
                                    </svg>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex w-full flex-col p-4">
                        <div>
                            <span className="text-lg font-semibold">{current?.user_name}</span>
                            <p className="text-sm">
                                {new Date().toLocaleString('default', {
                                    weekday: 'short',
                                    month: 'short',
                                    day: 'numeric',
                                })}
                            </p>
                        </div>
                    </div>
                </div>
                <button onClick={handleLogout} className="btn btn-outline">
                    Logout
                </button>
            </div>
        </div>
    );
};

export default ProfilePage;

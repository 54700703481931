import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { clearWarningMessage } from '../redux/notificationSlice';

const WarningNotification = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { warningMessage } = useSelector((state: RootState) => state.notification);
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        if (warningMessage) {
            setShowError(true);
            const timer = setTimeout(() => {
                setShowError(false);
                dispatch(clearWarningMessage());
            }, 5000);
            return () => {
                dispatch(clearWarningMessage());
                clearTimeout(timer);
            };
        }
    }, [dispatch, warningMessage]);

    return (
        showError &&
        warningMessage && (
            <div className="toast toast-center toast-top z-50 w-full md:w-1/3">
                <div className="alert alert-warning border-2 border-secondary bg-black text-center shadow-glow">
                    <span className="text-balance font-normal text-white">{warningMessage}</span>
                </div>
            </div>
        )
    );
};

export default WarningNotification;

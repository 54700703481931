import Close from 'icons/close';
import Filter from 'icons/filter';
import Locked from 'icons/locked';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { Trainer } from 'redux/trainerSlice';
import { getAllTrainers } from '../../redux/actions/trainerActions';

const AthletesSection = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { loading: loadingTrainers, trainers } = useSelector((state: RootState) => state.trainers);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        dispatch(
            getAllTrainers({
                page,
                take,
                search: debouncedSearch,
            })
        );
    }, [dispatch, page, take, debouncedSearch]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    const handleSelectTrainer = useCallback(
        (element: Trainer) => {
            element.status && navigate('/trainer/' + element.id);
        },
        [navigate]
    );

    const handleOpenFilter = useCallback(() => {
        setModalOpen(true);
    }, [dispatch]);

    return (
        <div className="w-full overflow-y-scroll px-6 md:h-5/6 lg:mb-0">
            <div className="z-20 flex w-full items-center justify-center gap-[10px] bg-black py-4">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search"
                        className="input h-[40px] w-full rounded-full bg-[#222222] px-4 py-2 focus:outline-none"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-3 top-2">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
                <button
                    onClick={handleOpenFilter}
                    className="flex h-[40px] items-center rounded-full border-secondary bg-primary px-4 py-2 text-secondary"
                >
                    <Filter />
                    <span className="ml-2">Filter</span>
                </button>
                {/* <WorkoutsFilterModal
                    modalOpen={modalOpen}
                    minDuration={minDuration}
                    maxDuration={maxDuration}
                    bodyPart={bodyPart}
                    setModalOpen={setModalOpen}
                    setMinDuration={setMinDuration}
                    setMaxDuration={setMaxDuration}
                    setBodyPart={setBodyPart}
                    setIntensity={setIntensity}
                    handleCloseFilter={handleCloseFilter}
                /> */}
            </div>
            {loadingTrainers ? (
                <div className="grid h-5/6 grid-cols-2 gap-5 overflow-y-scroll lg:grid-cols-6">
                    <div className="skeleton h-72 bg-secondary/10 px-2"></div>
                    <div className="skeleton h-72 bg-secondary/10 px-2"></div>
                    <div className="skeleton h-72 bg-secondary/10 px-2"></div>
                    <div className="skeleton h-72 bg-secondary/10 px-2"></div>
                    <div className="skeleton h-72 bg-secondary/10 px-2"></div>
                    <div className="skeleton h-72 bg-secondary/10 px-2"></div>
                </div>
            ) : (
                <div className="grid h-5/6 grid-cols-2 gap-5 overflow-y-scroll p-1 lg:grid-cols-6">
                    {trainers.map((trainer, index) => (
                        <div
                            key={index}
                            className={`card relative flex h-72 ${trainer.status && 'cursor-pointer hover:shadow-glow'} items-center justify-end rounded-2xl`}
                            onClick={() => handleSelectTrainer(trainer)}
                        >
                            {!trainer.status && (
                                <div className="absolute z-10 flex h-full w-full items-center justify-center">
                                    <Locked />
                                </div>
                            )}
                            <img
                                className={`absolute h-full w-full rounded-2xl object-cover ${!trainer.status && 'grayscale'}`}
                                src={trainer.profile_image}
                            />
                            <div className="absolute z-0 h-full w-full rounded-2xl bg-gradient-to-t from-black to-transparent to-50%"></div>
                            <p className="z-10 p-4 text-center font-semibold capitalize tracking-wider text-white">
                                {trainer.username}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AthletesSection;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { formToJSON } from 'axios';
import { connection } from 'util/axios';

export const featuredWorkouts = createAsyncThunk('workouts/featured', async (_, { rejectWithValue }) => {
    try {
        const response = await connection.get(`/workouts/featured`);
        return response.data.results;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Fetch failed');
    }
});

export const getWorkoutById = createAsyncThunk('workouts/id', async (props: { id: string }, { rejectWithValue }) => {
    try {
        const { id } = props;
        const response = await connection.get(`/workouts/${id}`);
        return response.data.results;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Fetch failed');
    }
});

export const getWorkoutVideos = createAsyncThunk(
    'workouts/videos',
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const response = await connection.get(`/workouts/${id}/playlist`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const createWorkoutSession = createAsyncThunk(
    'workouts/session-create',
    async (
        props: {
            workout_id: number;
            video_id: number;
            total_workouts: number;
            total_points: number;
            strake_days: number;
            feedback: string;
            workout_duration: number;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await connection.post(`/workouts/session/create`, props);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const getAllWorkouts = createAsyncThunk(
    'workouts/all',
    async (
        data: {
            page: number;
            take: number;
            search: string;
            dropdown?: boolean;
            min_duration?: number;
            max_duration?: number;
            categories?: string[];
            intensity?: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const { page, take, search, dropdown, min_duration, max_duration, categories, intensity } = data;
            const params = new URLSearchParams();
            params.append('page', page.toString());
            params.append('take', take.toString());
            params.append('search', search);
            if (intensity) params.append('intensity', intensity);
            if (dropdown) params.append('dropdown', dropdown.toString());
            if (min_duration) params.append('min_duration', min_duration.toString());
            if (max_duration) params.append('max_duration', max_duration.toString());
            if (categories && categories.length > 0) categories.map((cat) => params.append('categories', cat));
            const response = await connection.get(`/workouts?${params.toString()}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const getAllWorkoutVideos = createAsyncThunk(
    'workouts/all-video',
    async (
        data: {
            page: number;
            take: number;
            search?: string;
            dropdown?: boolean;
            min_duration?: number;
            max_duration?: number;
            categories?: string[];
            type?: string;
            name?: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const { page, take, search, min_duration, max_duration, categories, type, name } = data;
            const params = new FormData();
            params.append('page', page.toString());
            params.append('per_page', take.toString());
            params.append('search', search || '');
            if (min_duration) params.append('min_duration', min_duration.toString());
            if (max_duration) params.append('max_duration', max_duration.toString());
            if (type) params.append('exercise_type', type);
            if (name) params.append('exercise_name', name);
            if (categories && categories.length > 0) categories.forEach((cat) => params.append('categories', cat));
            const response = await connection.post(`/workouts/all/videos`, formToJSON(params));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

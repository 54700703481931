import { yupResolver } from '@hookform/resolvers/yup';
import LeftArrow from 'icons/left-arrow';
import RightArrow from 'icons/right-arrow';
import { useLayout } from 'pages/signup/layout';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import * as Yup from 'yup';
import { setNewUser } from '../../redux/authSlice';

interface IFormInput {
    gender: string;
    date_of_birth: string;
    height: string;
    isHeightFt: boolean;
    weight: string;
    isWeightLbs: boolean;
}

const SetProfilePage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { setPrevPath, setFooterText, setStep } = useLayout();
    const newUser = useSelector((state: RootState) => state.auth.newUser);

    useEffect(() => {
        setPrevPath('/auth/signup');
        setFooterText('We use this information to build better routines for you.');
        setStep(1);
    });

    const schema = Yup.object().shape({
        gender: Yup.string().nullable(),
        dateOfBirth: Yup.date().nullable().typeError('Invalid date'),
        height: Yup.string().nullable(),
        isHeightFt: Yup.boolean().nullable(),
        weight: Yup.string().nullable(),
        isWeightKg: Yup.boolean().nullable(),
    });

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            gender: newUser?.gender ? newUser.gender : null,
            date_of_birth: newUser?.date_of_birth ? newUser?.date_of_birth : new Date().toISOString().split('T')[0],
            height: newUser?.height,
            weight: newUser.weight,
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const onSubmit = useCallback(
        async (data) => {
            dispatch(setNewUser(data));
            navigate('/signup/set-goal');
        },
        [dispatch, navigate]
    );

    const handleGenderSelect = useCallback(
        (gender: string) => {
            setValue('gender', gender);
        },
        [setValue]
    );

    const handleChangeDateOfBirth = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            setValue('date_of_birth', event.target.value);
        },
        [setValue]
    );

    const handleSelectFt = useCallback(() => {
        setValue('isHeightFt', true);
    }, []);

    const handleSelectCm = useCallback(() => {
        setValue('isHeightFt', false);
    }, []);

    const handleSelectLbs = useCallback(() => {
        setValue('isWeightLbs', true);
    }, []);

    const handleSelectKgs = useCallback(() => {
        setValue('isWeightLbs', false);
    }, []);

    const handleChangeHeight = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            if (!watch('isHeightFt')) {
                const cm = parseInt(event.target.value);
                const ft = cm / 30.48;
                setValue('height', ft.toString());
            } else {
                setValue('height', event.target.value);
            }
        },
        [setValue]
    );

    const handleChangeWeight = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            if (!watch('isWeightLbs')) {
                const kg = parseInt(event.target.value);
                const lbs = kg * 2.20462;
                setValue('weight', lbs.toString());
            } else {
                setValue('weight', event.target.value);
            }
        },
        [setValue]
    );

    return (
        <div className="mt-16 flex h-full flex-col items-center justify-center gap-8 px-6">
            <p className="text-center text-2xl text-white lg:text-[56px] lg:font-semibold lg:tracking-wide">
                Let's personalize Your Profile
            </p>
            <div className="relative flex w-full flex-col items-center gap-8 lg:w-[600px]">
                <div className="h-full w-full">
                    <div className="flex w-full items-center justify-between gap-5">
                        <button
                            className={`h-10 w-full rounded-full border bg-black lg:h-14 lg:text-xl ${watch('gender') === 'male' ? 'border-secondary text-secondary shadow-glow' : 'border-white text-white'}`}
                            onClick={() => handleGenderSelect('male')}
                            {...register('gender')}
                        >
                            Male
                        </button>
                        <button
                            className={`h-10 w-full rounded-full border bg-black lg:h-14 lg:text-xl ${watch('gender') === 'female' ? 'border-secondary text-secondary shadow-glow' : 'border-white text-white'}`}
                            onClick={() => handleGenderSelect('female')}
                            {...register('gender')}
                        >
                            Female
                        </button>
                        <input type="hidden" {...register('gender')} />
                    </div>
                    {errors.gender && (
                        <div className="absolute -top-8 text-sm font-bold text-error opacity-50">
                            {errors.gender?.message}
                        </div>
                    )}
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex w-full flex-col items-center space-y-2 lg:w-[600px] lg:space-y-6"
                >
                    <div className="relative w-full">
                        <input
                            type="date"
                            placeholder="Date of Birth"
                            className="input input-bordered w-full rounded-full text-center text-xs focus:bg-[#131313] focus:outline-none lg:h-[56px] lg:text-base"
                            onChange={handleChangeDateOfBirth}
                            {...register('date_of_birth')}
                        />
                        <span className="absolute right-2 top-1/2 -translate-y-1/2 transform p-4 text-sm text-[#8E8E8E] lg:text-lg">
                            MM/DD/YYYY
                        </span>
                        <div className="absolute -top-10 left-0 p-4 text-sm font-bold text-error opacity-50">
                            {errors.date_of_birth && errors.date_of_birth?.message}
                        </div>
                    </div>
                    <div className="relative w-full">
                        <input
                            type="text"
                            name={'height'}
                            placeholder={'Height'}
                            className="input input-bordered w-full rounded-full text-xs text-white focus:bg-[#131313] focus:outline-none lg:h-[56px] lg:text-base"
                            onChange={handleChangeHeight}
                        />
                        <div className="absolute right-3 top-1/2 -translate-y-1/2 transform text-xs lg:text-base">
                            <div className="flex w-14">
                                <div
                                    onClick={handleSelectFt}
                                    className={`w-1/2 cursor-pointer rounded-full rounded-r-none p-[2px] text-center ${watch('isHeightFt') ? 'text-secondary shadow-glow' : ''}`}
                                >
                                    ft
                                </div>
                                <div
                                    onClick={handleSelectCm}
                                    className={`w-1/2 cursor-pointer rounded-full rounded-l-none p-[2px] text-center ${!watch('isHeightFt') ? 'text-secondary shadow-glow' : ''}`}
                                >
                                    cm
                                </div>
                            </div>
                        </div>
                        {errors.height && (
                            <div className="absolute left-1/3 top-0 p-4 text-sm font-bold text-error opacity-50">
                                {errors.height?.message}
                            </div>
                        )}
                    </div>
                    <div className="relative w-full">
                        <input
                            type="number"
                            name={'weight'}
                            placeholder="Weight"
                            className="input input-bordered flex w-full justify-between rounded-full text-xs text-white focus:bg-[#131313] focus:outline-none lg:h-[56px] lg:text-base"
                            onChange={handleChangeWeight}
                        />
                        <div className="absolute right-3 top-1/2 -translate-y-1/2 transform text-xs lg:text-base">
                            <div className="flex w-14">
                                <div
                                    onClick={handleSelectLbs}
                                    className={`w-1/2 cursor-pointer rounded-full rounded-r-none p-[2px] text-center ${watch('isWeightLbs') ? 'text-secondary shadow-glow' : ''}`}
                                >
                                    Lbs
                                </div>
                                <div
                                    onClick={handleSelectKgs}
                                    className={`w-1/2 cursor-pointer rounded-full rounded-l-none p-[2px] text-center ${!watch('isWeightLbs') ? 'text-secondary shadow-glow' : ''}`}
                                >
                                    Kg
                                </div>
                            </div>
                        </div>
                        {errors.weight && (
                            <div className="absolute left-1/3 top-0 p-4 text-sm font-bold text-error opacity-50">
                                {errors.weight?.message}
                            </div>
                        )}{' '}
                    </div>
                </form>
                <div className="flex w-full flex-col items-center justify-center">
                    <div className="flex w-full justify-between">
                        <Link
                            to={'/auth/signup'}
                            className="flex h-10 items-center gap-[10px] rounded-[30px] border border-white bg-black px-[17px] py-[10px] text-white hover:border-secondary hover:text-secondary hover:shadow-glow lg:h-14"
                        >
                            <LeftArrow />
                            {'Back'}
                        </Link>
                        <button
                            type="submit"
                            className="flex h-10 items-center gap-[10px] rounded-[30px] border border-secondary bg-black px-[17px] py-[10px] text-secondary shadow-glow lg:h-14"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {'Next'}
                            <RightArrow />
                        </button>
                    </div>
                    <Link
                        to="/signup/introduction"
                        className="p-4 text-center text-[10px] font-normal text-gray-600 underline lg:text-base"
                    >
                        Skip
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SetProfilePage;

import { ReactNode } from 'react';

const FooterPhrase = (props: { text: string | ReactNode }) => {
    return typeof props.text === 'string' ? (
        <p className="h-12 w-1/2 text-center text-xs font-extralight lg:text-lg">{props.text}</p>
    ) : (
        props.text
    );
};

export default FooterPhrase;

import { Clock, Fire } from 'icons';
import Close from 'icons/close';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { getAllPrograms } from '../../redux/actions/programActions';

const ProgramsLibrary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { allPrograms, loading } = useSelector((state: RootState) => state.programs);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);

    const handleSelectElement = useCallback(
        (element: any) => {
            navigate('/program/' + element.id);
        },
        [navigate]
    );

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        dispatch(getAllPrograms({ page, take, search: debouncedSearch }));
    }, [allPrograms.length, debouncedSearch, dispatch, page, search, take]);

    return (
        <div className="mb-16 w-full overflow-y-scroll md:h-5/6 lg:mb-0">
            <div className="z-20 flex items-center justify-center bg-black px-6 pt-6">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input input-bordered w-full rounded-full border p-2"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-2 top-3">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
            </div>
            <div className="flex w-full flex-col items-center space-y-4 overflow-y-scroll p-2 pb-16 lg:pb-0">
                {loading
                    ? [0, 1, 2].map((val) => (
                          <div key={val} className="skeleton h-48 w-full bg-secondary/20 lg:h-64"></div>
                      ))
                    : allPrograms.map((program, index) => (
                          <div
                              onClick={() => handleSelectElement(program)}
                              id={index.toString()}
                              key={index}
                              className={`flex w-full cursor-pointer justify-center rounded-lg`}
                          >
                              <div
                                  className="card relative h-full w-full border border-neutral-800 shadow-xl hover:border-secondary hover:shadow-glow"
                                  style={{
                                      backgroundImage: `url(${program.cover_image})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center 20%',
                                  }}
                              >
                                  <div className="card-body z-10 h-48 items-start justify-center p-4 lg:h-64">
                                      <h2 className="text-lg text-white">{program.title}</h2>
                                      <div className="card-actions flex-col items-start">
                                          <div className="flex items-center rounded-full border border-white bg-black/50">
                                              <div className="flex items-center px-2 text-sm">
                                                  <Fire />
                                                  <span className="text-xs">
                                                      Difficulty: x {program.program_difficulty}
                                                  </span>
                                              </div>
                                              <div className="flex items-center px-2">
                                                  <Clock />
                                                  <span className="text-xs">{program.program_duration % 60} min</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      ))}
            </div>
        </div>
    );
};

export default ProgramsLibrary;

import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'components/CheckoutForm';
import config from 'config/config';
import { useLayout } from 'pages/signup/layout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import LoadingSpinner from '../../components/LoadingSpinner';

const appearance: Appearance = {
    theme: 'night',
    variables: {
        fontFamily: 'Sohne, system-ui, sans-serif',
        fontWeightNormal: '500',
        borderRadius: '8px',
        colorBackground: '#000000',
        colorPrimary: '#fff',
        accessibleColorOnColorPrimary: '#1A1B25',
        colorText: 'white',
        colorTextSecondary: 'white',
        colorTextPlaceholder: '#ABB2BF',
        tabIconColor: 'white',
        logoColor: 'dark',
    },
    rules: {
        '.Input': {
            backgroundColor: '#000',
            border: '1px solid var(--colorPrimary)',
        },
    },
};

const CheckoutPage = () => {
    const { setPrevPath, setFooterText } = useLayout();
    const { loading } = useSelector((state: RootState) => state.user);
    const { clientSecret } = useSelector((state: RootState) => state.user);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        setPrevPath('/signup/choose-plan');
        setFooterText(
            <div className="w-full text-center text-[0.6rem] leading-6">
                <p>By placing your order, you confirm you have read, understand, and accept the</p>
                <div className="flex flex-row flex-wrap justify-center gap-y-0">
                    <span className="text-base font-semibold text-secondary">Sweatsonic</span>™{' '}
                    <a
                        href="https://sweatsonic.com/pages/terms-of-service"
                        className="link-hover link px-1 text-secondary underline"
                    >
                        Terms and Conditions
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://sweatsonic.com/pages/privacy-policy"
                        className="link-hover link pl-1 text-secondary underline"
                    >
                        Privacy Policy
                    </a>
                    , and{' '}
                    <a
                        href="https://sweatsonic.com/pages/medical-disclaimer"
                        className="link-hover link text-secondary underline"
                    >
                        Medical Disclaimer
                    </a>
                    .
                </div>
            </div>
        );
    }, []);

    useEffect(() => {
        setStripePromise(loadStripe(config.stripe_public_key));
    }, []);

    return (
        <div className="mt-12 flex w-full flex-col justify-start px-6">
            {!loading && stripePromise && clientSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                    <CheckoutForm />
                </Elements>
            ) : (
                <LoadingSpinner message="Saving Your Payment Details..." className="pt-40" />
            )}
        </div>
    );
};

export default CheckoutPage;

import { Fire } from 'icons';
import Calendar from 'icons/calendar';
import { Link } from 'react-router-dom';

export type ProgramCardProps = {
    id: string;
    title: string;
    image: string;
    description: { difficulty: number; duration: number };
};
export type ProgramsCarouselProps = {
    loading: boolean;
    title?: string;
    elements: ProgramCardProps[];
    handleSelectElement: (element) => void;
};

const ProgramsCarousel = (props: ProgramsCarouselProps) => {
    const { title, elements, loading, handleSelectElement } = props;
    return (
        <div className={`w-full lg:h-2/3`}>
            {loading ? (
                <div className="flex h-full w-full flex-col">
                    <div className="my-2 flex w-5/6 items-center pl-4">
                        <div className="skeleton h-6 w-2/3 bg-secondary/10 px-2"></div>
                    </div>
                    <div className="flex h-full w-full pl-4">
                        <div className={`skeleton h-[180px] w-[382px] bg-secondary/10`}></div>
                    </div>
                </div>
            ) : (
                <div className="flex w-full flex-col items-center justify-center gap-4 border-b border-[#313131]/50 pb-6">
                    {title && (
                        <div className="flex h-[29px] w-full justify-between px-6">
                            <h1 className="text-2xl text-white">{title}</h1>
                            <Link to="/library/programs" className="flex items-center underline">
                                View All
                            </Link>
                        </div>
                    )}
                    <div className="carousel w-full lg:overflow-x-scroll">
                        {elements.map((element, index) => (
                            <div
                                onClick={() => handleSelectElement(element)}
                                id={index.toString()}
                                key={index}
                                className={`carousel-item flex h-full cursor-pointer items-center justify-center gap-4 rounded-lg p-6 pt-1`}
                            >
                                <div
                                    className="card relative h-[180px] w-[382px] border-[5px] border-[#131313] shadow-xl hover:shadow-glow"
                                    style={{
                                        backgroundImage: `url(${element.image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center 20%',
                                    }}
                                >
                                    <div className="card-body z-10 items-start justify-end p-4">
                                        <div className="card-actions flex-col items-start">
                                            <div className="flex items-center space-x-2 rounded-full border border-white bg-black/50 px-4 py-1 text-white">
                                                <div className="flex items-center gap-[2px]">
                                                    <Fire />
                                                    <p className="text-[10px]">{element.description.difficulty}</p>
                                                </div>
                                                <div className="flex items-center gap-[2px]">
                                                    <Calendar />
                                                    <p className="text-[10px]">Days {element.description.duration}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="text-2xl text-white">{element.title}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProgramsCarousel;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import exerciseReducer from './exerciseSlice';
import leaderboardReducer from './leaderboardSlice';
import notificationReducer from './notificationSlice';
import programReducer from './programSlice';
import stripeReducer from './stripeSlice';
import trainerReducer from './trainerSlice';
import userReducer from './userSlice';
import videoReducer from './videoSlice';
import workoutReducer from './workoutSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    workouts: workoutReducer,
    programs: programReducer,
    trainers: trainerReducer,
    videos: videoReducer,
    user: userReducer,
    stripe: stripeReducer,
    notification: notificationReducer,
    leaderboard: leaderboardReducer,
    exercise: exerciseReducer,
});

const authTransform = createTransform(
    (inboundState: any) => {
        const { loading, ...rest } = inboundState;
        return rest;
    },
    (outboundState) => ({
        ...outboundState,
        loading: false,
    }),
    { whitelist: ['auth'] }
);

const persistConfig = {
    key: 'auth',
    storage,
    transforms: [authTransform],
    whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
                ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                ignoredPaths: ['items.dates'],
            },
        }),
    devTools: true,
});

const persistor = persistStore(store);

export { persistor, store };
export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

const LoadingSpinner = ({ message, className }: { message: string; className?: string }) => {
    return (
        <div className={`${className} flex flex-col items-center justify-center gap-4 text-white`}>
            <span className="loading loading-spinner loading-lg"></span>
            <span className="text-sm">{message}</span>
        </div>
    );
};

export default LoadingSpinner;

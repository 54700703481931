import InstructionsCarousel, { InstructionsCarouselProps } from 'components/home/discover/InstructionsCarousel';
import ProgramsCarousel, { ProgramsCarouselProps } from 'components/home/discover/ProgramsCarousel';
import WorkoutsCarousel, { WorkoutCard, WorkoutCarouselProps } from 'components/home/discover/WorkoutCarousel';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { getFeaturedPrograms } from '../../redux/actions/programActions';
import { getFeaturedTrainers } from '../../redux/actions/trainerActions';
import { getAllVideos } from '../../redux/actions/videoActions';
import { featuredWorkouts } from '../../redux/actions/workoutActions';
import { Trainer } from '../../redux/trainerSlice';

const DiscoverSection = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { loading: loadingWorkouts, featuredWorkouts: workouts } = useSelector((state: RootState) => state.workouts);
    const { loading: loadingPrograms, featuredPrograms } = useSelector((state: RootState) => state.programs);
    const { loading: loadingTrainers, featuredTrainers } = useSelector((state: RootState) => state.trainers);
    const { loading: loadingVideos, allVideos: videos } = useSelector((state: RootState) => state.videos);
    const [workoutsData, setWorkoutsData] = useState([]);
    const [programsData, setProgramsData] = useState([]);
    const [trainersData, setTrainersData] = useState([]);
    const [page] = useState(1);

    const handleSelectWorkout = useCallback(
        (element: WorkoutCard) => {
            navigate('/workout/' + element.id);
        },
        [navigate]
    );

    const handleSelectProgram = useCallback(
        (element: WorkoutCard) => {
            navigate('/program/' + element.id);
        },
        [navigate]
    );

    const handleSelectInstructionVideo = useCallback((element: WorkoutCard) => {
        console.log(element);
    }, []);

    const handleSelectTrainer = useCallback(
        (element: Trainer) => {
            navigate('/trainer/' + element.id);
        },
        [navigate]
    );

    useEffect(() => {
        workouts.length === 0 && dispatch(featuredWorkouts());
    }, [dispatch, workouts.length]);

    useEffect(() => {
        videos.length === 0 &&
            dispatch(
                getAllVideos({
                    page: 1,
                    take: 10,
                    exerciseType: 'Instructional',
                    featured: 'true',
                })
            );
    }, [dispatch, videos.length]);

    useEffect(() => {
        featuredPrograms.length === 0 && dispatch(getFeaturedPrograms());
    }, [dispatch, featuredPrograms.length]);

    useEffect(() => {
        featuredTrainers.length === 0 && dispatch(getFeaturedTrainers());
    }, [dispatch, page, featuredTrainers]);

    useEffect(() => {
        setWorkoutsData(
            workouts.map((workout) => {
                return {
                    id: workout.id,
                    title: workout.title,
                    image: workout.workout_cover_image,
                    description: {
                        difficulty: workout.difficulty,
                        duration: workout.workout_duration,
                    },
                };
            })
        );
        setProgramsData(
            featuredPrograms.map((program) => {
                return {
                    id: program.id,
                    title: program.title,
                    image: program.cover_image,
                    description: {
                        difficulty: program.program_difficulty,
                        duration: program.program_duration,
                    },
                };
            })
        );
        setTrainersData(
            featuredTrainers.map((trainer) => {
                return {
                    id: trainer.id,
                    title: trainer.username,
                    image: trainer.profile_image,
                };
            })
        );
    }, [featuredPrograms, featuredTrainers, videos, workouts]);

    const programsProps: ProgramsCarouselProps = {
        title: 'Featured Programs',
        elements: programsData,
        handleSelectElement: handleSelectProgram,
        loading: loadingPrograms,
    };
    const workoutProps: WorkoutCarouselProps = {
        title: 'Featured Workouts',
        elements: workoutsData,
        handleSelectElement: handleSelectWorkout,
        loading: loadingWorkouts,
    };
    const trainersProps: WorkoutCarouselProps = {
        title: 'Featured Trainers',
        elements: trainersData,
        handleSelectElement: handleSelectTrainer,
        loading: loadingTrainers,
    };
    const videosProps: InstructionsCarouselProps = {
        title: 'Featured Instructions',
        elements: videos,
        handleSelectElement: handleSelectInstructionVideo,
        loading: loadingVideos,
    };
    return (
        <div className="mb-16 flex flex-col gap-6 overflow-y-scroll pt-4 md:h-5/6 lg:mb-0">
            <ProgramsCarousel {...programsProps} />
            <WorkoutsCarousel {...workoutProps} />
            <WorkoutsCarousel {...trainersProps} />
            <InstructionsCarousel {...videosProps} />
        </div>
    );
};

export default DiscoverSection;

import { Link } from 'react-router-dom';
import BackIcon from '../assets/img/icons/back-icon.png';
import ICLogo from '../assets/img/logo.png';
import AppLogo from '../assets/img/logotype2.png';

const Hero = (props: { prevPath?: string; hideSlogan?: boolean; progress?: number }) => {
    const { prevPath, hideSlogan, progress } = props;

    return (
        <div className="flex h-full w-full items-center justify-center">
            {progress && (
                <progress
                    className="progress progress-secondary absolute top-1 h-1 w-screen rounded-none"
                    value={progress}
                    max={11}
                    style={{ boxShadow: '0 0 10px rgba(255, 255, 255, 0.7)' }}
                ></progress>
            )}
            {prevPath && (
                <Link to={prevPath} className="absolute left-10 top-10">
                    <div className="rounded-full bg-black">
                        <img alt="back" src={BackIcon} className="w-18" />
                    </div>
                </Link>
            )}
            <div className="flex flex-col items-center gap-[16px] text-center">
                <img alt="sweatsonic" src={ICLogo} className="w-[120px]" />
                <img alt="sweatsonic" src={AppLogo} />
                {!hideSlogan && <span className="text-[16px] text-white lg:text-4xl">The Music Is Your Trainer</span>}
            </div>
        </div>
    );
};
export default Hero;

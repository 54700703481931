import { yupResolver } from '@hookform/resolvers/yup';
import LeftArrow from 'icons/left-arrow';
import RightArrow from 'icons/right-arrow';
import { useLayout } from 'pages/signup/layout';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

interface IFormInput {
    purpose: string;
}

const SetPurposePage = () => {
    const navigate = useNavigate();
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const { setPrevPath, setFooterText, setStep } = useLayout();

    useEffect(() => {
        setPrevPath('/signup/set-activity');
        setFooterText('You can always adjust later.');
        setStep(5);
    });

    const schema = Yup.object().shape({
        purpose: Yup.string(),
    });

    const { register, handleSubmit, setValue } = useForm<IFormInput>({
        defaultValues: {
            purpose: localStorage.getItem('purpose'),
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const selectedOptionHandler = (e) => {
        setValue('purpose', e.target.textContent);
        setShowDropDown(false);
    };
    const onSubmit = async (value) => {
        localStorage.setItem('purpose', value.purpose);
        navigate('/signup/introduction');
    };

    const options = [
        'I am confident in my appearance and abilities.',
        'I am becoming stronger and more capable every day.',
        'I am energized and less fatigued.',
        'I am focused and mentally sharp.',
        'I am calm and less stressed.',
        'I am achieving my goals and pushing myself further.',
        'I am setting a positive example for my friends and family.',
        'I am living a long, healthier life',
    ];

    return (
        <div className="mt-[3.5rem] flex h-full flex-col items-center justify-center gap-11 px-6 text-white">
            <div className="flex flex-col items-center">
                <p className="text-2xl lg:text-4xl">What's your Purpose</p>
                <p className="text-sm lg:text-lg">For committing to fitness?</p>
            </div>
            <div className="flex w-2/3 flex-col items-center justify-center lg:text-xl">
                <p className="text-center capitalize">Constantly reminding yourself why you're doing this</p>
                <p className="text-center capitalize text-secondary">
                    Will help you push through and stay comitted to improving
                </p>
            </div>
            <form className="form-control relative w-full rounded-2xl border-2 border-secondary">
                <input
                    onClick={() => setShowDropDown(!showDropDown)}
                    tabIndex={0}
                    type="text"
                    placeholder="Type / Select"
                    className={`select select-ghost select-md w-full rounded-full border-none focus:outline-none`}
                    {...register('purpose')}
                />
                {showDropDown && (
                    <ul
                        tabIndex={0}
                        id="dropdown"
                        className="bg-base-10 flex flex-col gap-4 rounded-2xl px-6 pb-5 pt-4"
                    >
                        {options.map((option) => (
                            <li
                                onClick={(e) => selectedOptionHandler(e)}
                                className="cursor-pointer rounded-full text-sm hover:text-secondary"
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                )}
            </form>
            <div className="flex w-full flex-col items-center justify-center">
                <div className="flex w-full justify-between">
                    <Link
                        to={'/signup/set-activity'}
                        className="flex h-10 items-center gap-[10px] rounded-[30px] border border-white bg-black px-[17px] py-[10px] text-white hover:border-secondary hover:text-secondary hover:shadow-glow lg:h-14"
                    >
                        <LeftArrow />
                        {'Back'}
                    </Link>
                    <button
                        type="submit"
                        className="flex h-10 items-center gap-[10px] rounded-[30px] border border-secondary bg-black px-[17px] py-[10px] text-secondary shadow-glow lg:h-14"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {'Next'}
                        <RightArrow />
                    </button>
                </div>
                <Link
                    to="/signup/introduction"
                    className="p-4 text-center text-[10px] font-normal text-gray-600 underline lg:text-base"
                >
                    Skip
                </Link>
            </div>
        </div>
    );
};

export default SetPurposePage;

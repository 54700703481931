import Video from 'components/Video';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { Video as VideoProps } from 'redux/trainerSlice';

const TrainerVideoPage = () => {
    const { id, type } = useParams();
    const { trainerProfile } = useSelector((state: RootState) => state.trainers);
    const [video, setVideo] = useState<VideoProps>();

    useEffect(() => {
        switch (type) {
            case 'cadence':
                setVideo(trainerProfile.videos.cadence.find((video) => video.id === parseInt(id)));
                break;
            case 'hitt':
                setVideo(trainerProfile.videos.hitt.find((video) => video.id === parseInt(id)));
                break;
            default:
                console.log('error type', type);
                break;
        }
    }, []);

    const handleFinishVideo = useCallback((video, watchedTime) => {}, []);

    const handleVideoTick = useCallback(() => {}, []);

    const handleFinishSession = useCallback(() => {}, []);

    return video ? (
        <Video
            videos={[
                {
                    stream_url: null,
                    video_url: video.video_url,
                    trainer_name: video.trainer_name,
                    title: video.title,
                    thumbnail_image: video.video_thumbnail_image,
                },
            ]}
            videoIndex={0}
            backPath={`/trainer/${video.trainer_id}`}
            autoPlay={true}
            handleFinishSession={handleFinishSession}
            handleFinishVideo={handleFinishVideo}
            handleVideoTick={handleVideoTick}
        />
    ) : (
        <div className="flex h-dvh w-full items-center justify-center">
            <div className="loading loading-ring h-24 w-24 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
    );
};

export default TrainerVideoPage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentUser, login, oauthAppleLogin, oauthGoogleLogin, registerUser } from './actions/authActions';

export interface User {
    isMember: boolean;
    sub: string;
    iss: string;
    client_id: string;
    origin_jti: string;
    event_id: string;
    token_use: string;
    scope: string;
    auth_time: number;
    exp: number;
    iat: number;
    jti: string;
    username: string;
    userId: string;
    user_name: string;
    profile_image: string;
}

export interface NewUser {
    full_name: string;
    username: string;
    password: string;
    email: string;
    date_of_birth: string;
    gender: string;
    goal: string;
    activity_level: string;
    weight: string;
    height: string;
    profile_picture: string;
    stripe_id: string;
    purpose: string;
    workout_length: string;
}

export interface AuthState {
    access_token: string;
    email_verified: boolean;
    refresh_token: string;
    oauth_id_token: string;
    current?: User;
    newUser?: NewUser;
    loading: boolean;
    error?: string;
    identity_provider?: string;
}

const initialState: AuthState = {
    access_token: '',
    email_verified: false,
    refresh_token: '',
    oauth_id_token: '',
    identity_provider: 'email',
    loading: false,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        refreshToken(state, action: PayloadAction<any>) {
            state.access_token = action.payload.access_token;
        },
        logout: (state) => {
            state.access_token = '';
            state.email_verified = false;
            state.refresh_token = '';
            state.error = null;
        },
        setNewUser: (state, action: PayloadAction<Partial<User>>) => {
            state.newUser = {
                ...state.newUser,
                ...action.payload,
            };
        },
        resetNewUser: (state) => {
            state.newUser = null;
        },
        clearAuthState: (state) => {
            state.access_token = initialState.access_token;
            state.email_verified = initialState.email_verified;
            state.refresh_token = initialState.refresh_token;
            state.current = initialState.current;
            state.newUser = initialState.newUser;
            state.loading = initialState.loading;
            state.error = initialState.error;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.email_verified = action.payload.email_verified;
                state.access_token = action.payload.access_token;
                state.refresh_token = action.payload.refresh_token;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(oauthGoogleLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(oauthAppleLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCurrentUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.current = action.payload;
                state.error = null;
            })
            .addCase(getCurrentUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action: PayloadAction<any>) => {
                state.error = null;
                state.access_token = action.payload.access_token;
                state.refresh_token = action.payload.refresh_token;

                // Reset these values
                state.identity_provider = 'email';
                state.oauth_id_token = null;
            })
            .addCase(oauthGoogleLogin.fulfilled, (state, action: PayloadAction<any>) => {
                if (action.payload.status === 'ACTIVE') {
                    state.error = null;
                    state.access_token = action.payload.access_token;
                    state.refresh_token = action.payload.refresh_token;
                } else if (action.payload.status === 'REQUIRES_REGISTRATION') {
                    state.oauth_id_token = action.payload.oauth_id_token;
                    state.identity_provider = 'google';
                    state.error = null;
                }

                state.loading = false;
            })
            .addCase(oauthAppleLogin.fulfilled, (state, action: PayloadAction<any>) => {
                if (action.payload.status === 'ACTIVE') {
                    state.error = null;
                    state.access_token = action.payload.access_token;
                    state.refresh_token = action.payload.refresh_token;
                } else if (action.payload.status === 'REQUIRES_REGISTRATION') {
                    state.oauth_id_token = action.payload.oauth_id_token;
                    state.identity_provider = 'apple';
                    state.error = null;
                }

                state.loading = false;
            })
            .addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { refreshToken, logout, setNewUser, resetNewUser, clearAuthState } = authSlice.actions;

export default authSlice.reducer;

// src/pages/auth/FullNamePrompt.tsx

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { setNewUser } from '../../redux/authSlice';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface FullNameFormInput {
    full_name: string;
}

const FullNamePrompt = () => {
    const dispatch = useDispatch<AppDispatch>();
    const fullName = useSelector((state: RootState) => state.auth.newUser?.full_name || '');
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FullNameFormInput>({
        defaultValues: { full_name: fullName },
    });

    const onSubmit: SubmitHandler<FullNameFormInput> = (data) => {
        dispatch(setNewUser({ full_name: data.full_name }));
        navigate('/signup/set-profile');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-col items-center gap-4 p-4">
            <p className="label text-center text-2xl text-white">Create New Account</p>
            <div className="relative w-full">
                <input
                    type="text"
                    placeholder="Full Name"
                    className={`input input-bordered w-full rounded-full text-xs text-white focus:bg-[#131313] focus:outline-none ${
                        errors.full_name ? 'border-warning' : ''
                    }`}
                    {...register('full_name', {
                        required: 'Full name is required',
                        minLength: {
                            value: 2,
                            message: 'Full name must be at least 2 characters',
                        },
                        maxLength: {
                            value: 50,
                            message: 'Full name cannot exceed 50 characters',
                        },
                    })}
                />
                {errors.full_name && (
                    <div className="absolute flex h-4 w-full items-center justify-start px-2 text-sm text-error">
                        <span className="text-xs">{errors.full_name.message}</span>
                    </div>
                )}
            </div>
            <button
                type="submit"
                className={`mt-4 h-[40px] w-[150px] rounded-full border border-white bg-black text-white`}
                disabled={!!errors.full_name}
            >
                Continue
            </button>
        </form>
    );
};

export default FullNamePrompt;

import { yupResolver } from '@hookform/resolvers/yup';
import LeftArrow from 'icons/left-arrow';
import RightArrow from 'icons/right-arrow';
import { useLayout } from 'pages/signup/layout';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import * as Yup from 'yup';
import { setNewUser } from '../../redux/authSlice';

interface IFormInput {
    goal: string | null;
}

const SetgoalPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const newUser = useSelector((state: RootState) => state.auth.newUser);
    const { setPrevPath, setFooterText, setStep } = useLayout();

    useEffect(() => {
        setPrevPath('/signup/set-profile');
        setFooterText('You can always adjust later.');
        setStep(2);
    });

    const schema = Yup.object().shape({
        goal: Yup.string().nullable(),
    });

    const { register, watch, handleSubmit } = useForm<IFormInput>({
        defaultValues: {
            goal: newUser.goal ? newUser.goal : null,
        },
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = useCallback(
        async (value) => {
            dispatch(setNewUser(value));
            navigate('/signup/set-preference');
        },
        [dispatch, navigate]
    );

    const options = ['lose fat', 'build muscle', 'improve endurance', 'maintain'];

    return (
        <div className="mt-16 flex h-full flex-col items-center justify-center gap-7 px-6">
            <p className="text-center text-2xl text-white lg:text-4xl">What's your goal?</p>
            <form className="flex w-full flex-col items-center gap-14">
                <div className="flex w-full flex-col gap-4 lg:w-[600px]">
                    {options.map((option) => (
                        <label
                            className={`flex h-[40px] cursor-pointer items-center justify-center rounded-full border bg-black lg:h-20 lg:text-2xl ${watch('goal') && watch('goal').includes(option) ? 'border-secondary text-secondary shadow-glow' : 'border-white text-white'}`}
                        >
                            <span className="capitalize">{option}</span>
                            <input
                                type="radio"
                                checked={watch('goal') && watch('goal').includes(option)}
                                className="hidden"
                                value={option}
                                {...register('goal')}
                            />
                        </label>
                    ))}
                </div>
                <div className="flex w-full flex-col items-center justify-center">
                    <div className="flex w-full justify-between">
                        <Link
                            to={'/signup/set-profile'}
                            className="flex h-10 items-center gap-[10px] rounded-[30px] border border-white bg-black px-[17px] py-[10px] text-white hover:border-secondary hover:text-secondary hover:shadow-glow lg:h-14"
                        >
                            <LeftArrow />
                            {'Back'}
                        </Link>
                        <button
                            type="submit"
                            className="flex h-10 items-center gap-[10px] rounded-[30px] border border-secondary bg-black px-[17px] py-[10px] text-secondary shadow-glow lg:h-14"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {'Next'}
                            <RightArrow />
                        </button>
                    </div>
                    <Link
                        to="/signup/introduction"
                        className="p-4 text-center text-[10px] font-normal text-gray-600 underline lg:text-base"
                    >
                        Skip
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default SetgoalPage;

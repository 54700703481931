import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllTrainers, getFeaturedTrainers, getTrainerProfile } from './actions/trainerActions';

export interface Trainer {
    id: number;
    title: string;
    username: string;
    slug: string;
    description: string;
    status: number;
    created_at: string;
    updated_at: string;
    cover_image: string;
    featured: boolean;
    user_id: number;
    total_followers: string;
    monthly_plays: number;
    profile_image: string;
    thumb_image: string;
    social_ig: string;
    social_tt: string;
    social_fb: string;
    social_yt: string;
    social_tw: string;
    gender: number;
    squad: boolean;
}

export interface Workout {
    cover_image: string;
    cover_image_mobile: string;
    difficulty: number;
    duration: number;
    id: number;
    title: string;
}

export interface Program {
    cover_image: string;
    cover_image_mobile: string;
    difficulty: number;
    duration: number;
    id: number;
    title: string;
}

export interface Video {
    brief: string;
    created_at: string;
    equipment_id: number;
    exercise_id: string;
    external_link: string;
    featured: boolean;
    id: number;
    is_favorite: boolean;
    musicArtist: string;
    music_genre_id: string;
    points: number;
    published: boolean;
    published_at: string;
    repCount: string;
    setCount: string;
    title: string;
    trainer_id: number;
    trainer_name: string;
    type: string;
    type_of_video: string;
    updated_at: string;
    video_duration: number;
    video_thumbnail_image: string;
    video_url: string;
}

export interface TrainerProfile {
    workouts_count: number;
    profile: {
        id: number;
        title: string;
        username: string;
        slug: string;
        description: string;
        status: number;
        created_at: string;
        updated_at: string;
        cover_image: string;
        profile_image: string;
        thumb_image: string;
        social_ig: string;
        social_tt: string;
        social_fb: string;
        social_yt: string;
        social_tw: string;
        gender: number;
        squad: boolean;
        featured: boolean;
        user_id: number;
        total_followers: string;
        monthly_plays: number;
    };
    favourite_videos: any[];
    favourite_workouts: Workout[];
    favourite_programs: Program[];
    videos: {
        cadence: Video[];
        hitt: Video[];
    };
}

export interface TrainerState {
    trainerProfile?: TrainerProfile;
    trainers: Trainer[];
    featuredTrainers: Trainer[];
    loading: boolean;
    error: string;
}

const initialState: TrainerState = {
    trainers: [],
    featuredTrainers: [],
    loading: false,
    error: null,
};

const trainerSlice = createSlice({
    name: 'trainers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllTrainers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllTrainers.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.trainers = action.payload.trainers;
                state.error = null;
            })
            .addCase(getAllTrainers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getFeaturedTrainers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getFeaturedTrainers.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.featuredTrainers = action.payload;
                state.error = null;
            })
            .addCase(getFeaturedTrainers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getTrainerProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getTrainerProfile.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.trainerProfile = action.payload;
                state.error = null;
            })
            .addCase(getTrainerProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const {} = trainerSlice.actions;

export default trainerSlice.reducer;

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? true : false;

const isProd = window.location.hostname === 'app.sweatsonic.com' ? true : false;

const config = {
    defaults: {
        namespace: 'Application',
        api_url: isLocalhost
            ? 'http://localhost:4000'
            : isProd
              ? 'https://api.sweatsonic.com'
              : 'https://api-dev.sweatsonic.com',
    },
    stripe_public_key: isProd
        ? 'pk_live_51IEPFoKg2zMGhaBRkENkV75XKWjRKuhScegr71Qriz3XdzdYu24yDAZtjNMfGMEB4uiKsIUyaZtIWGaWpTjLWanQ00pUfHn8um'
        : 'pk_test_51IEPFoKg2zMGhaBRelsrjcCAzpwiLn3RyLb4HIK7s0gLA0IzJKUowHLrqI0AqyqZWcESSU697CWAGIkaph6WV2RN00kZYzjRd0',
    oauth_google_client_id: isProd
        ? '204040026485-pcfpng0hdudo1c2j6e322mh134ducvf3.apps.googleusercontent.com'
        : '204040026485-pcfpng0hdudo1c2j6e322mh134ducvf3.apps.googleusercontent.com',
    oauth_apple_client_id: isProd ? 'com.sweatsonic.SweatSonic.web' : 'com.sweatsonic.SweatSonic.web.dev',
    oauth_apple_redirect_uri: isProd
        ? 'https://app.sweatsonic.com/apple-callback'
        : 'https://app-dev.sweatsonic.com/apple-callback',
};

export default config;

import { useGoogleLogin } from '@react-oauth/google';
import AppleLogo from 'assets/img/icons/apple-logo.svg';
import GoogleLogo from 'assets/img/icons/google-logo.svg';
import config from 'config/config';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';

import { oauthAppleLogin, oauthGoogleLogin } from '../../redux/actions/authActions';
import { setNewUser } from '../../redux/authSlice';
import { warningNotification } from '../../redux/notificationSlice';
import FullNamePrompt from './full-name-prompt';
import { useLayout } from './layout';

export interface GoogleAuthTokenPayload {
    iss: string;
    azp: string;
    aud: string;
    sub: string;
    hd?: string;
    email: string;
    email_verified: boolean;
    at_hash: string;
    name: string;
    picture: string;
    given_name: string;
    family_name: string;
    iat: number;
    exp: number;
}

const Login = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { setPrevPath, setHideSlogan } = useLayout();
    const { loading, oauth_id_token, identity_provider } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        setPrevPath(null);
        setHideSlogan(false);
    });

    const handleGoogleSuccess = useCallback(
        async (response) => {
            console.log('Google Oauth response ', response);
            const result = await dispatch(oauthGoogleLogin({ code: response.code }));

            if (result.payload.status === 'REQUIRES_REGISTRATION') {
                /* Decode JWT token */
                const { name, picture, email }: GoogleAuthTokenPayload = jwtDecode(result.payload.oauth_id_token);

                dispatch(
                    setNewUser({
                        full_name: name,
                        profile_picture: picture,
                        email: email,
                    })
                );

                navigate('/signup/set-profile');
            }

            if (result.payload.status === 'INCORRECT_IDENTITY_PROVIDER') {
                dispatch(warningNotification(result.payload.message));
                navigate('/auth');
            }
        },
        [dispatch]
    );

    const handleGoogleFailure = useCallback(() => {
        dispatch(warningNotification('Google login failure'));
    }, []);

    const handleAppleSuccess = useCallback(async (response) => {
        console.log('Apple login ', response);
        const result = await dispatch(oauthAppleLogin({ tokenId: response.authorization.id_token }));

        if (result.payload.status === 'REQUIRES_REGISTRATION') {
            /* Decode JWT token */
            const { email, email_verified }: any = jwtDecode(result.payload.oauth_id_token);

            if (email && email_verified) {
                dispatch(
                    setNewUser({
                        email: email,
                    })
                );
            }
        }

        if (result.payload.status === 'INCORRECT_IDENTITY_PROVIDER') {
            dispatch(warningNotification(result.payload.message));
            navigate('/auth');
        }
    }, []);

    const handleAppleFailure = useCallback((error) => {
        dispatch(warningNotification('Apple login failure'));
    }, []);

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (response) => handleGoogleSuccess(response),
        onError: () => handleGoogleFailure(),
        flow: 'auth-code',
        ux_mode: 'popup',
    });

    if (identity_provider === 'apple' && oauth_id_token?.length) {
        /* Display full name prompt*/
        return (
            <div className="flex h-full w-full flex-col items-center justify-start overflow-auto px-6">
                <FullNamePrompt />
            </div>
        );
    } else {
        return (
            <div className="flex h-full w-full flex-col items-center justify-center overflow-auto px-6">
                {loading ? (
                    <>
                        <div className="flex flex-col items-center justify-center gap-4">
                            <span className="loading loading-spinner loading-lg"></span>
                            <span>Logging in</span>
                        </div>
                    </>
                ) : (
                    <>
                        <p className="mb-[16px] text-center text-[16px] tracking-wide text-white lg:text-2xl">
                            Designed To Keep You Consistent
                        </p>
                        <div className="flex w-full flex-col items-center gap-6">
                            <Link
                                to={'/auth/signin'}
                                className="flex h-[40px] w-full items-center justify-center rounded-full border border-white bg-black text-[16px] text-white shadow-glow"
                            >
                                Sign In
                            </Link>
                            <Link
                                to={'/auth/signup'}
                                className="flex h-[40px] w-full items-center justify-center rounded-full border border-white bg-black text-[16px] text-white"
                            >
                                Create A New Account
                            </Link>
                            <div className="h-[1px] w-[250px] rounded-full bg-[#313131] text-center opacity-50"></div>
                            <AppleSignin
                                authOptions={{
                                    clientId: config.oauth_apple_client_id,
                                    scope: 'email name',
                                    usePopup: true,
                                    redirectURI: config.oauth_apple_redirect_uri,
                                }}
                                uiType="dark"
                                render={(props) => (
                                    <button
                                        {...props}
                                        className="flex h-[40px] w-full items-center justify-center gap-[16px] rounded-full border border-white bg-black text-[16px] text-white"
                                    >
                                        <img className="mx-2 h-[24px] w-[24px]" src={AppleLogo} alt="apple-logo" />
                                        Continue With Apple
                                    </button>
                                )}
                                onSuccess={handleAppleSuccess}
                                onError={handleAppleFailure}
                            />
                            <button
                                onClick={() => handleGoogleLogin()}
                                className="flex h-[40px] w-full items-center justify-center gap-[16px] rounded-full border border-white bg-black text-[16px] text-white"
                            >
                                <img className="mx-2 h-[24px] w-[24px]" src={GoogleLogo} alt="google-logo" />
                                Continue With Google
                            </button>
                        </div>
                    </>
                )}
            </div>
        );
    }
};

export default Login;

import ProfilePicture from 'assets/img/signup/profile-picture.png';
import { useLayout } from 'pages/signup/layout';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { updateProfilePicture } from '../../redux/actions/userActions';

const SetProfilePicturePage = () => {
    const { setPrevPath, setFooterText } = useLayout();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { loading } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        setPrevPath('/signup/choose-plan');
        setFooterText("You're almost there.");
    }, [setPrevPath]);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setSelectedFile(file);
    }, []);

    const handleSubmit = useCallback(() => {
        if (selectedFile) {
            dispatch(updateProfilePicture({ file: selectedFile }));
            navigate('/');
        } else {
            alert('Please select a file first.');
        }
    }, [dispatch, navigate, selectedFile]);

    return (
        <div className="mt-16 flex h-full flex-col items-center gap-12 text-white">
            <span className="text-2xl lg:text-4xl">Profile Picture</span>
            <div className="flex w-1/2 flex-col items-center justify-center space-y-4 font-normal">
                <div className="flex items-center justify-start">
                    {selectedFile ? (
                        <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Profile"
                            className="h-32 w-32 rounded-full object-cover"
                        />
                    ) : (
                        <img src={ProfilePicture} alt="Profile" className="h-32 w-32 rounded-full object-cover" />
                    )}
                </div>
                <input
                    type="file"
                    className="file-input file-input-bordered w-full max-w-xs"
                    accept="image/*"
                    onChange={handleFileChange}
                />
            </div>
            <div className="flex flex-col items-center">
                <p className="text-secondary">Make Sure Your Picture Is:</p>
                <p>
                    <span className="text-secondary">I.</span> less than 2MB
                </p>
                <p>
                    <span className="text-secondary">II.</span> has dimensions smaller than 1200x1200px
                </p>
            </div>
            <div className="w-full px-8">
                <button
                    onClick={handleSubmit}
                    className="flex h-[40px] w-full items-center justify-center rounded-full border border-white bg-black"
                    disabled={loading}
                >
                    Save & Continue
                </button>
            </div>
        </div>
    );
};

export default SetProfilePicturePage;

import Logotype from 'icons/logotype';

const DesktopTopBar = () => {
    return (
        <div className="pointer-events-none absolute inset-0 z-40 hidden w-full justify-center lg:flex">
            <div className="flex h-[48px] w-full items-center justify-center rounded-b-xl border border-l-0 border-r-0 border-t-0 border-secondary bg-[#131313] shadow-glow">
                <Logotype className="h-8" />
            </div>
        </div>
    );
};

export default DesktopTopBar;

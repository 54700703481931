import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { clearConfirmMessage } from '../redux/notificationSlice';

const ConfirmationNotification = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { confirmMessage } = useSelector((state: RootState) => state.notification);
    const [showNotification, setShowNotification] = useState<boolean>(false);

    useEffect(() => {
        if (confirmMessage) {
            setShowNotification(true);
            const timer = setTimeout(() => {
                setShowNotification(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
                dispatch(clearConfirmMessage());
            };
        }
    }, [confirmMessage, dispatch]);

    return (
        showNotification &&
        confirmMessage && (
            <div className="toast toast-center toast-top z-50 w-full md:w-1/3">
                <div className="alert alert-success border-2 border-secondary bg-black text-center shadow-glow">
                    <span className="text-balance font-normal text-white">{confirmMessage}</span>
                </div>
            </div>
        )
    );
};

export default ConfirmationNotification;

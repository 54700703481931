const Tabs = (props: { active: string; setActive: any; tabs: string[] }) => {
    const { active, setActive, tabs } = props;

    return (
        <div role="tablist" className="tabs tabs-bordered w-full justify-items-center gap-[10px] px-6">
            {tabs.map((tab) => (
                <button
                    key={tab}
                    onClick={() => setActive(tab)}
                    role="tab"
                    className={`tab tab-border-3 w-full text-[12px] font-bold capitalize tracking-wider hover:tab-active md:text-xl ${active === tab ? 'tab-active !border-secondary font-semibold text-secondary' : 'font-normal'}`}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
};

export default Tabs;

import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import * as yup from 'yup';
import { changePassword } from '../../redux/actions/userActions';
import { confirmNotification, warningNotification } from '../../redux/notificationSlice';
import { setResetPasswordEmail } from '../../redux/userSlice';
import { useLayout } from './layout';

interface IFormInput {
    email: string;
}

const ForgotPasswordPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {
        resetPasswordState: { loading, error, responseMessage, active },
    } = useSelector((state: RootState) => state.user);
    const { setPrevPath, setHideSlogan } = useLayout();

    const schema = yup.object().shape({
        email: yup.string().required('Email/Username is required'),
    });

    useEffect(() => {
        setPrevPath('/auth');
    }, [setPrevPath]);

    useEffect(() => {
        setHideSlogan(true);
    }, [setHideSlogan]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema) as any,
    });

    const onSubmit: SubmitHandler<IFormInput> = useCallback(
        (data) => {
            dispatch(setResetPasswordEmail(data.email));
            dispatch(changePassword(data));
        },
        [dispatch]
    );

    useEffect(() => {
        active && navigate('/auth/confirm-code');
    }, [active, navigate]);

    useEffect(() => {
        dispatch(confirmNotification(responseMessage));
    }, [responseMessage, dispatch, navigate]);

    useEffect(() => {
        dispatch(warningNotification(error));
    }, [error, dispatch]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-control flex h-full w-full flex-col items-center justify-start gap-[32px] px-[24px]"
        >
            <p className="text-2xl leading-7 text-white">Forgot Password?</p>
            <div className="flex w-full flex-col justify-evenly gap-3">
                <p className="text-sm font-normal leading-4 text-white">Give us your email to verify it's you.</p>
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Email"
                        autoComplete="username"
                        className={`input input-bordered relative w-full rounded-full text-xs text-[#8E8E8E] focus:bg-[#131313] ${errors.email ? 'border-warning' : ''}`}
                        {...register('email')}
                    />
                </div>
                <div className="flex flex-col items-center justify-center text-[12px]">
                    <p>
                        <Link to={'/auth/signin'} className="text-secondary underline">
                            Sign In
                        </Link>{' '}
                        /{' '}
                        <Link to={'/auth/signup'} className="text-secondary underline">
                            Sign Up
                        </Link>
                    </p>
                </div>
            </div>
            <div className="flex w-full justify-center p-2">
                <button
                    type="submit"
                    className={`h-[40px] w-[150px] rounded-full border border-white bg-black text-white`}
                    disabled={loading}
                >
                    {loading ? 'Sending...' : 'Send'}
                </button>
            </div>
        </form>
    );
};

export default ForgotPasswordPage;

import DesktopTopBar from 'components/DesktopTopBar';
import FooterPhrase from 'components/FooterPhrase';
import Hero from 'components/Hero';
import { ReactNode, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

type ContextType = {
    prevPath: string | null;
    setPrevPath: (prevPath: string | null) => void;
    setStep: (step: number | null) => void;
    setFooterText: (footerText: string | ReactNode | null) => void;
};

const SignupLayout = () => {
    const [prevPath, setPrevPath] = useState<string | null>(null);
    const [step, setStep] = useState<number | null>(null);
    const [footerText, setFooterText] = useState<string | ReactNode | null>(null);

    return (
        <div className='flex h-dvh w-full flex-col items-center justify-start bg-smoke-blue bg-cover font-["SF-Pro-Display"]'>
            <div className="hidden items-center lg:flex">
                <DesktopTopBar />
            </div>
            <div className="mt-12 flex items-end lg:hidden">
                <Hero prevPath={prevPath} hideSlogan={true} progress={step} />
            </div>
            <div className={`flex w-full flex-col overflow-auto lg:h-auto lg:w-fit lg:pt-16`}>
                <Outlet context={{ prevPath, setPrevPath, setStep, setFooterText } satisfies ContextType} />
            </div>
            {footerText && (
                <div
                    className={`flex w-full justify-center lg:h-auto lg:max-w-[1440px] ${footerText && 'border'} mb-4 mt-auto border-b-0 border-l-0 border-r-0 border-t-[#313131]/50 p-[5px]`}
                >
                    <FooterPhrase text={footerText} />
                </div>
            )}
        </div>
    );
};

export function useLayout() {
    return useOutletContext<ContextType>();
}

export default SignupLayout;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const getAllTrainers = createAsyncThunk(
    'trainers/all',
    async (props: { search; page; take }, { rejectWithValue }) => {
        try {
            const { search, page, take } = props;
            const response = await connection.get(`/trainers?page=${page}&take=${take}`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const getFeaturedTrainers = createAsyncThunk('trainers/featured', async (_, { rejectWithValue }) => {
    try {
        const response = await connection.get(`/trainers/featured`);
        return response.data.results;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Fetch');
    }
});

export const getTrainerProfile = createAsyncThunk(
    'trainers/profile',
    async (props: { id: number }, { rejectWithValue }) => {
        try {
            const response = await connection.get(`/trainers/profile/${props.id}`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

import DailyWorkouts from 'icons/navbar/daily-workouts';
import Home from 'icons/navbar/home';
import Library from 'icons/navbar/library';
import Profile from 'icons/navbar/profile';
import { useLocation, useNavigate } from 'react-router-dom';

const BottomNav = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="btm-nav absolute z-30 bg-[#131313] text-[#8E8E8E] lg:hidden">
            <button
                onClick={() => navigate('/')}
                className={location.pathname === '/' ? 'active bg-[#131313] text-secondary' : ''}
            >
                <Home fill="currentColor" />
                <span className="text-xs">Explore</span>
            </button>
            <button
                onClick={() => navigate('/library/workouts')}
                className={location.pathname.includes('library') ? 'active bg-[#131313] text-secondary' : ''}
            >
                <Library fill="currentColor" />
                <span className="text-xs">Library</span>
            </button>
            <button
                onClick={() => navigate('/workout')}
                className={location.pathname === '/workout' ? 'active bg-[#131313] text-secondary' : ''}
                disabled={true}
            >
                <DailyWorkouts fill="currentColor" />
                <span className="text-xs">Daily Workout</span>
            </button>
            <button
                onClick={() => navigate('/profile')}
                className={location.pathname === '/profile' ? 'active bg-[#131313] text-secondary' : ''}
            >
                <Profile fill="currentColor" />
                <span className="text-xs">Profile</span>
            </button>
        </div>
    );
};

export default BottomNav;

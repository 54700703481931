import { Clock } from 'icons';
import Followers from 'icons/followers';
import PlayCircle from 'icons/play-circle';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Video } from 'redux/trainerSlice';
import { secondsToMinutes } from 'util/time';

const TrainerVideosCarousel = (props: { videos: Video[]; show: boolean; loading: boolean; type: string }) => {
    const { videos, show, loading, type } = props;
    const navigate = useNavigate();

    const handleVideoSelect = useCallback(
        (video: Video) => {
            navigate(`/trainer/${type}/${video.id}`);
        },
        [navigate]
    );

    return !show ? (
        <div className="flex h-48 w-full items-center justify-center">
            <p className="text-lg text-gray-500">No videos available</p>
        </div>
    ) : (
        <div className="carousel h-48 w-full">
            {loading ? (
                <div className="flex h-48 w-full">
                    <div className="h-full w-1/2 p-2 lg:w-1/4">
                        <div className="skeleton h-full w-full bg-secondary/25"></div>
                    </div>
                    <div className="h-full w-1/2 p-2 lg:w-1/4">
                        <div className="skeleton h-full w-full bg-secondary/25"></div>
                    </div>
                    <div className="h-full w-1/2 p-2 lg:w-1/4">
                        <div className="skeleton h-full w-full bg-secondary/25"></div>
                    </div>
                    <div className="h-full w-1/2 p-2 lg:w-1/4">
                        <div className="skeleton h-full w-full bg-secondary/25"></div>
                    </div>
                </div>
            ) : (
                videos?.map((video, index) => (
                    <div
                        key={index}
                        onClick={() => handleVideoSelect(video)}
                        className="carousel-item w-2/3 cursor-pointer lg:w-1/4"
                    >
                        <div
                            onClick={() => console.log('onClick card')}
                            className="card relative h-full w-full border-secondary p-2 hover:border"
                        >
                            <img
                                src={video?.video_thumbnail_image}
                                alt="video"
                                className="h-1/2 rounded-t-lg object-cover"
                            />
                            <div className="card-body relative h-1/2 flex-col items-start justify-start rounded-b-lg bg-neutral-900 p-2 text-xs">
                                <p className="flex text-white">
                                    <Clock className="h-full w-4" />
                                    <span className="self-center px-2">
                                        Duration - {secondsToMinutes(video.video_duration)} MIN
                                    </span>
                                </p>
                                <p className="flex">
                                    <Followers className="h-full" />
                                    <span className="self-center px-2">Creator: {video.trainer_name}</span>
                                </p>
                                <button className={'absolute right-0 top-0'}>
                                    <PlayCircle className="" />
                                </button>
                                <p className="text-white">{video.title}</p>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default TrainerVideosCarousel;
